import "./Modal.scss";

export const DeleteSmth = ({ title, span, id, opened, onConfirm }) => {
  return (
    <div
      className="modal fade show"
      id="ModalPassword"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ModalPasswordLabel"
      aria-hidden="true"
    >
      <div role="document" className="modal-dialog modal-lg delete-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Delete {title}</h4>
          </div>
          <div className="modal-body">
            Do you want to delete {title.toLowerCase()} <strong>{span}</strong>?
          </div>
          <div className="modal-footer">
            <button className="change" onClick={() => onConfirm(id)}>
              Yes
            </button>
            <button className="btn btn-default" onClick={() => opened("")}>
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
