import moment from "moment";

export const downloadFile = (data, type) => {
  const blob = new Blob([data], { type });
  const url = window.URL.createObjectURL(blob);
  const pwa = window.open(url);
  if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
    alert("Please disable your Pop-up blocker and try again.");
  }
};

const getISOIgnoreTimezone = (date) => {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const onStartDateSelect = (date) => {
  const startDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0,
    0,
    0,
    0
  );
  return getISOIgnoreTimezone(startDate);
};

export const onEndDateSelect = (date) => {
  const endDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59,
    999
  );
  return getISOIgnoreTimezone(endDate);
};

export const onDateTimeSelect = (date) => {
  const startDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    0,
    0
  );
  return getISOIgnoreTimezone(startDate);
};

export const getFormattedDate = (date) => {
  return moment(date).format("MM/DD/YYYY h:mm:ss A");
};

export const getTimeFromMS = (s) => {
  var pad = (n, z = 2) => ("00" + n).slice(-z);
  return (
    pad((s / 3.6e6) | 0) +
    ":" +
    pad(((s % 3.6e6) / 6e4) | 0) +
    ":" +
    pad(((s % 6e4) / 1000) | 0)
  );
};

export const getRelativeDate = (date) => {
  const dateDifference = moment().diff(moment.utc(date));
  if (moment.duration(dateDifference)._data.years !== 0)
    return `${moment.duration(dateDifference)._data.years} year${
      moment.duration(dateDifference)._data.years !== 1 ? "s" : ""
    } ago`;
  else if (moment.duration(dateDifference)._data.months !== 0)
    return `${moment.duration(dateDifference)._data.months} month${
      moment.duration(dateDifference)._data.months !== 1 ? "s" : ""
    } ago`;
  else if (moment.duration(dateDifference)._data.days !== 0)
    return `${moment.duration(dateDifference)._data.days} day${
      moment.duration(dateDifference)._data.days !== 1 ? "s" : ""
    } ago`;
  else if (moment.duration(dateDifference)._data.hours !== 0)
    return `${moment.duration(dateDifference)._data.hours} hour${
      moment.duration(dateDifference)._data.hours !== 1 ? "s" : ""
    } ago`;
  else if (moment.duration(dateDifference)._data.minutes !== 0)
    return `${moment.duration(dateDifference)._data.minutes} minute${
      moment.duration(dateDifference)._data.minutes !== 1 ? "s" : ""
    } ago`;
  else if (moment.duration(dateDifference)._data.seconds !== 0)
    return "just now";
  else return getFormattedDate(date);
};

export const convertThousands = (x, units) => {
  let l = 0,
    n = parseInt(x, 10) || 0;
  while (n >= 1000 && ++l) {
    n = n / 1000;
  }
  return (
    (parseInt(n * 100) / 100)
      .toString()
      .slice(0, (parseInt(n * 100) / 100).toString()[4] !== "." ? 5 : 4) +
    units[l]
  );
};

export const modifyAddress = (search = "", name = [], addedParameter = "") => {
  return search.length !== 0
    ? "?" +
        [
          ...search
            .replace("?", "")
            .split("&")
            .filter((value) => !name.some((r) => value.includes(r))),
          addedParameter,
        ]
          .filter((value) => value.length !== 0)
          .join("&")
    : addedParameter.length !== 0
    ? "?" + addedParameter
    : "";
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
