export const SET_SIDEBAR = "SET_SIDEBAR";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

export const setSidebar = (isOpen) => ({
  type: SET_SIDEBAR,
  isOpen,
});

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  page,
});
