import request from "../utils/request";

export function getPackaging(params) {
  return request({
    url: "/order/active",
    method: "get",
    headers: params,
  });
}

export function submitOrder(uuid, params) {
  return request({
    url: `order/submit/${uuid}`,
    method: "post",
    headers: params
  })
}