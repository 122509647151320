import { startProd, stopProd, statusOrder } from "../../api/settings";
import { getRestaurants } from "../../api/app";

export const CHANGE_SETTINGS_VALUE = "CHANGE_SETTINGS_VALUE";
export const RESET_SETTINGS_STORE = "RESET_SETTINGS_STORE";

export const START_PROD_PROCESS = "START_PROD_PROCESS";
export const START_PROD_SUCCESS = "START_PROD_SUCCESS";
export const START_PROD_ERROR = "START_PROD_ERROR";

export const STOP_PROD_PROCESS = "STOP_PROD_PROCESS";
export const STOP_PROD_SUCCESS = "STOP_PROD_SUCCESS";
export const STOP_PROD_ERROR = "STOP_PROD_ERROR";

export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const GET_RESTAURANTS_RESULTS = "GET_RESTAURANTS_RESULTS";
export const GET_RESTAURANTS_ERROR = "GET_RESTAURANTS_ERROR";

export const startProdAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: START_PROD_PROCESS });
    try {
      const response = await startProd(params);
      if (response) {
        dispatch({ type: START_PROD_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: START_PROD_ERROR });
      console.log(error);
    }
  };
};

export const stopProdAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: STOP_PROD_PROCESS });
    try {
      const response = await stopProd(params);
      if (response) {
        dispatch({ type: STOP_PROD_SUCCESS });
      }
    } catch (error) {
      dispatch({ type: STOP_PROD_ERROR });
      console.log(error);
    }
  };
};

export const getStatus = () => {
  return async (dispatch) => {
    try {
      const response = await statusOrder();
      const { success, results } = response;
      if (success) {
        dispatch(changeValue("status", results.status));
        dispatch(
          changeValue(
            "testTime",
            `${
              results.testTime && results.testTime[0]
                ? results.testTime[0].toString().padStart(2, "0")
                : "00"
            }:${
              results.testTime && results.testTime[1]
                ? results.testTime[1].toString().padStart(2, "0")
                : "00"
            }`
          )
        );
        dispatch(
          changeValue(
            "pickerTime",
            `${
              results.testTime && results.testTime[0]
                ? results.testTime[0].toString().padStart(2, "0")
                : "00"
            }:${
              results.testTime && results.testTime[1]
                ? results.testTime[1].toString().padStart(2, "0")
                : "00"
            }`
          )
        );
        dispatch(
          changeValue(
            "startTime",
            `${
              results.startTime && results.startTime[3]
                ? results.startTime[3].toString().padStart(2, "0")
                : "00"
            }:${
              results.startTime && results.startTime[4]
                ? results.startTime[4].toString().padStart(2, "0")
                : "00"
            }`
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getRestaurantsAction = (setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RESTAURANTS });
    try {
      const response = await getRestaurants();
      const { success, results } = response;
      if (success) {
        setData(
          results.content.map((restaurant) => ({
            label: `${restaurant.number} - ${restaurant.name}`,
            value: restaurant.uuid,
          }))
        );
        dispatch({ type: GET_RESTAURANTS_RESULTS, payload: results.content });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RESTAURANTS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SETTINGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_SETTINGS_STORE,
});
