import request from "../utils/request";

export function signIn(params) {
  return request({
    url: "/auth/signIn",
    method: "post",
    data: params,
    skipAuthRefresh: true,
  });
}

export function refresh() {
  return request({
    url: "/auth/refresh",
    method: "post",
    skipAuthRefresh: true,
  });
}

export function signOut() {
  return request({
    url: "/auth/logout",
    method: "post",
  });
}

export function getRestaurants() {
  return request({
    url: `/restaurants`,
    method: "get",
  });
}

export function checkCode(params) {
  return request({
    url: "/auth/multiFactor/verify",
    method: "post",
    data: params,
  });
}

export function checkRecoveryCode(params) {
  return request({
    url: `/auth/multiFactor/verify/recoveryCode`,
    method: "post",
    data: params,
  });
}
