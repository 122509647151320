import { SET_CURRENT_PAGE, SET_SIDEBAR } from "../actions/appAction";

const initialState = {
  sidebar: true,
  currentPage: "Settings",
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return { ...state, sidebar: action.isOpen };

    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.page };

    default:
      return state;
  }
};

export default appReducer;
