import * as types from "../actions/packagingAction";

const initialState = {
  loading: false,
  totalResults: 0,
  started: true,
  status: "INACTIVE",
  startTime: "00:00",
  testTime: "00:00",
};

const packagingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PACKAGING_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.GET_PACKAGING:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PACKAGING_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.GET_PACKAGING_ERROR:
      return {
        ...initialState,
        loading: false,
      };
    case types.SUBMIT_ORDER:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_PACKAGING_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default packagingReducer;
