import { getLogs } from "../../api/logs";

export const CHANGE_LOGS_VALUE = "CHANGE_LOGS_VALUE";
export const CHANGE_LOGS_DATE_VALUE = "CHANGE_LOGS_DATE_VALUE";

export const RESET_LOGS_STORE = "RESET_LOGS_STORE";

export const GET_LOGS = "GET_LOGS";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_ERROR = "GET_LOGS_ERROR";

export const SUBMIT_ORDER = "SUBMIT_ORDER";

export const getLogsAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_LOGS });
    try {
      const response = await getLogs(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_LOGS_SUCCESS, params: params, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_LOGS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_LOGS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_LOGS_DATE_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_LOGS_STORE,
});
