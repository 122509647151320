import moment from "moment";
import * as types from "../actions/transactionsAnalyticAction";

const initialState = {
  content: null,
  locationNum: null,
  destinationCode: "select",
  activeTab: "graphic",
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  loading: false,
};

const transactionsAnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TRANSACTIONS_ANALYTIC_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_TRANSACTIONS_ANALYTIC_TIME_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
        interval: action.payload.interval,
      };
    case types.GET_TRANSACTIONS_ANALYTIC:
      return {
        ...state,
        loading: true,
      };

    case types.GET_TRANSACTIONS_ANALYTIC_SUCCESS:
      let tooltips =
        action.payload[0].length !== 0
          ? Object.keys(action.payload[0].values[0][1])
          : [];
      let restaurants =
        action.payload.length !== 0
          ? action.payload.map((item) => item.locationNum)
          : [];
      return {
        ...state,
        loading: false,
        content: {
          restaurants:
            action.payload.length !== 0
              ? action.payload.map((item) => item.locationNum)
              : [],
          graphicTooltip: action.payload[0].values.map(
            (
              value,
              index
            ) => `<table style="margin-top: 0.5rem;"><thead><tr><th>Code</th>${restaurants.map(
              (rest) => `<th style="padding: 0px 3px 0px 3px;">${rest}</th>`
            )}</tr></thead><tbody>
        ${tooltips
          .filter(
            (tool) =>
              action.payload.filter(
                (rest) => Number(rest.values[index][1][tool]) !== 0
              ).length !== 0
          )
          .map(
            (tooltip) =>
              `<tr><td>${tooltip}</td>${restaurants.map(
                (rest) =>
                  `<td style="padding: 0px 3px 0px 3px;">${
                    action.payload.find((val) => val.locationNum === rest)
                      .values[index][1][tooltip]
                  }</td>`
              )}</tr>`
          )}
        <tr><td><b>Total</b></td>${action.payload.map(
          (rest) =>
            `<td style="padding: 0px 3px 0px 3px;"><b>${Object.values(
              rest.values[index][1]
            ).reduce(function (sum, elem) {
              return sum + Number(elem);
            }, 0)}</b></td>`
        )}</tr>
            </tbody></table>`
          ),
          graphicValues:
            action.payload.length !== 0
              ? action.payload[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  ...action.payload.map((it) =>
                    Object.values(it.values[index][1]).reduce(function (
                      sum,
                      elem
                    ) {
                      return sum + Number(elem);
                    },
                    0)
                  ),
                ])
              : [],
          totalValues: action.payload
            .map((item) => ({
              restaurant: item.locationNum,
              value: item.values.reduce(function (sum, elem) {
                return (
                  sum +
                  Number(
                    Object.values(elem[1]).reduce(function (summ, el) {
                      return summ + Number(el);
                    }, 0)
                  )
                );
              }, 0),
            }))
            .sort((a, b) => b.value - a.value),
        },
      };

    case types.GET_TRANSACTIONS_ANALYTIC_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_TRANSACTIONS_ANALYTIC_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default transactionsAnalyticReducer;
