import * as types from "../actions/historyAction";

const initialState = {
  uuid: "",
  locationNum: null,
  loading: false,
  totalResults: 0,
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  isFileLoading: false,
  size: "20",
  offset: 0,
  page: 0,
  chartLoading: false,
  graphContent: [],
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_HISTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_HISTORY_DATE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
        interval: action.payload.interval,
      };
    case types.GET_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case types.GET_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.totalElements,
      };
    case types.GET_HISTORY_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.message,
      };

    case types.GET_ORDERS_SMALL_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
      };

    case types.GET_ORDERS_SMALL_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading:
          (action.payload.params.locationNum === state.locationNum ||
            (action.payload.params.locationNum === null &&
              state.locationNum === "select")) &&
          action.payload.params.interval === state.interval &&
          ((state.timeInterval !== null &&
            Number(state.timeInterval) ===
              Math.floor(
                (new Date(action.payload.params.endEventTime).getTime() -
                  new Date(action.payload.params.startEventTime).getTime()) /
                  1000
              )) ||
            (new Date(action.payload.params.startEventTime).getTime() ===
              state.startDate &&
              new Date(action.payload.params.endEventTime).getTime() ===
                state.endDate))
            ? false
            : state.chartLoading,
        graphContent:
          (action.payload.params.locationNum === state.locationNum ||
            (action.payload.params.locationNum === null &&
              state.locationNum === "select")) &&
          action.payload.params.interval === state.interval &&
          ((state.timeInterval !== null &&
            Number(state.timeInterval) ===
              Math.floor(
                (new Date(action.payload.params.endEventTime).getTime() -
                  new Date(action.payload.params.startEventTime).getTime()) /
                  1000
              )) ||
            (new Date(action.payload.params.startEventTime).getTime() ===
              state.startDate &&
              new Date(action.payload.params.endEventTime).getTime() ===
                state.endDate))
            ? action.payload.results.length !== 0
              ? action.payload.results[0].values.map((item, index) => [
                  new Date(item[0] * 1000),
                  action.payload.results.reduce(function (summ, it) {
                    return (
                      summ +
                      Object.values(it.values[index][1]).reduce(function (
                        sum,
                        elem
                      ) {
                        return sum + Number(elem);
                      },
                      0)
                    );
                  }, 0),
                ])
              : [0, 0]
            : state.graphContent,
      };
    case types.GET_ORDERS_SMALL_ANALYTIC:
      return {
        ...state,
        chartLoading: false,
      };
    case types.LOAD_HISTORY_FILE:
      return {
        ...state,
        isFileLoading: true,
      };
    case types.LOAD_HISTORY_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };
    case types.LOAD_HISTORY_FILE_ERROR:
      return {
        ...initialState,
        isFileLoading: false,
      };
    case types.RESET_HISTORY_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default historyReducer;
