import * as types from "../actions/logsAction";

const initialState = {
  loading: false,
  totalResults: 0,
  page: 0,
  size: "20",
  startDate: null,
  endDate: null,
  timeInterval: null,
  logLevel: null,
  message: "",
  data: [],
};

const logsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_LOGS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_LOGS_DATE_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_LOGS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_LOGS_SUCCESS:
      return {
        ...state,
        ...((state.logLevel === action.params.logLevel ||
          (state.logLevel === "select" && !action.params.logLevel)) &&
        state.message === action.params.message &&
        ((((state.startDate === "select" && !action.params.startPeriod) ||
          new Date(state.startDate).toISOString() ===
            action.params.startPeriod) &&
          ((state.endDate === "select" && !action.params.endPeriod) ||
            new Date(state.endDate).toISOString() ===
              action.params.endPeriod)) ||
          (state.timeInterval !== null &&
            Number(state.timeInterval) ===
              Math.floor(
                (new Date(action.params.endPeriod).getTime() -
                  new Date(action.params.startPeriod).getTime()) /
                  1000
              )))
          ? {
              loading: false,
              totalResults: action.payload.totalElements,
              data: action.payload.content,
            }
          : {}),
      };
    case types.GET_LOGS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.SUBMIT_ORDER:
      return {
        ...initialState,
        loading: false,
      };
    case types.RESET_LOGS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default logsReducer;
