import * as types from "../actions/transactionsAction";

const initialState = {
  columns: [],
  restaurantUuid: "select",
  interval: null,
  startDate: null,
  endDate: null,
  timeInterval: null,
  locationNum: null,
  limit: "20",
  page: 0,
  sortBy: "beginTime",
  orderDirection: "DESC",
  loading: false,
  scrollLoading: false,
  loadingDetails: false,
  orders: [],
  isFileLoading: false,
  cursorDirection: "FIRST",
  cursor: null,
  existNext: false,
  existPrevious: false,
  changePage: false,
  chartLoading: false,
  graphContent: [],
};

const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_TRANSACTIONS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_TRANSACTIONS_DATE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
        interval: action.payload.interval,
      };
    case types.GET_TRANSACTIONS:
      return {
        ...state,
        loading: action.payload,
        scrollLoading: !action.payload,
      };

    case types.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        scrollLoading: false,
        cursor: action.payload.cursor,
        cursorDirection: action.payload.cursorDirection,
        existNext: action.payload.existsNextPage,
        existPrevious: action.payload.existsPreviousPage,
      };

    case types.GET_TRANSACTIONS_ERROR:
      return {
        ...state,
        loading: false,
      };

    case types.GET_TRANSACTIONS_SMALL_ANALYTIC:
      return {
        ...state,
        chartLoading: true,
      };

    case types.GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS:
      return {
        ...state,
        chartLoading:
          (action.payload.params.locationNum === state.locationNum ||
            (action.payload.params.locationNum === null &&
              state.locationNum === "select")) &&
          action.payload.params.interval === state.interval &&
          ((state.timeInterval !== null &&
            Number(state.timeInterval) ===
              Math.floor(
                (new Date(action.payload.params.endEventTime).getTime() -
                  new Date(action.payload.params.startEventTime).getTime()) /
                  1000
              )) ||
            (new Date(action.payload.params.startEventTime).getTime() ===
              state.startDate &&
              new Date(action.payload.params.endEventTime).getTime() ===
                state.endDate))
            ? false
            : state.chartLoading,
        graphContent:
          (action.payload.params.locationNum === state.locationNum ||
            (action.payload.params.locationNum === null &&
              state.locationNum === "select")) &&
          action.payload.params.interval === state.interval &&
          ((state.timeInterval !== null &&
            Number(state.timeInterval) ===
              Math.floor(
                (new Date(action.payload.params.endEventTime).getTime() -
                  new Date(action.payload.params.startEventTime).getTime()) /
                  1000
              )) ||
            (new Date(action.payload.params.startEventTime).getTime() ===
              state.startDate &&
              new Date(action.payload.params.endEventTime).getTime() ===
                state.endDate))
            ? Object.keys(action.payload.results).length !== 0
              ? Object.keys(action.payload.results).map((key) => [
                  new Date(
                    new Date(key).getTime() -
                      new Date().getTimezoneOffset() * 60000
                  ),
                  action.payload.results[key],
                ])
              : [0, 0]
            : state.graphContent,
      };
    case types.GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR:
      return {
        ...state,
        chartLoading: false,
      };

    case types.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        loadingDetails: true,
      };

    case types.GET_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        loadingDetails: false,
        orders: [...state.orders, action.payload],
      };

    case types.GET_TRANSACTION_DETAILS_ERROR:
      return {
        ...state,
        loadingDetails: false,
      };

    case types.RESET_TRANSACTIONS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default transactionsReducer;
