import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import SidebarItem from "./SidebarItem";

import "./Sidebar.scss";

export const Sidebar = ({
  sidebar,
  items,
  currentItem,
  onItemClick = null,
}) => {
  const history = useHistory();
  const [activeItem, setActiveItem] = useState("");
  const tooltipRef = useRef(null);

  return (
    <div className="sidebar-container">
      <aside className={sidebar ? "sidebar" : "sidebar sidebar-hidden"}>
        <nav className="sidebar-menu">
          <ul className="list-unstyled">
            {items.map((item, index) => (
              <SidebarItem
                key={index}
                activeItem={activeItem}
                setActiveItem={setActiveItem}
                link={item.link}
                i={item.i}
                span={item.span}
                disabled={item.disabled}
                tooltip={item.tooltip}
                active={currentItem === item.span}
                tooltipRef={tooltipRef}
                onClick={onItemClick}
              />
            ))}
          </ul>
        </nav>

        <footer className="sidebar-footer">
          {" "}
          © {new Date().getFullYear()} Chick-fil-A. All rights reserved.{" "}
        </footer>
      </aside>

      {activeItem &&
      activeItem.span.length !== 0 &&
      items.find((it) => it.span === activeItem.span).tooltip &&
      currentItem !== activeItem.span ? (
        <div
          className="sidebar--clickable-tooltip"
          ref={tooltipRef}
          style={{ top: activeItem.top }}
          onMouseLeave={(e) => {
            if (
              !items
                .map((item) => item.link)
                .includes(e.nativeEvent?.relatedTarget?.id || "sidebar") &&
              e.nativeEvent?.relatedTarget?.className !== "sidebar"
            )
              setActiveItem(false);
          }}
        >
          <ul>
            {items
              .find((it) => it.span === activeItem.span)
              .tooltip.map((item) => (
                <li>
                  <a
                    to={item.link}
                    className={
                      item.disabled ? "disabled" : item.active ? `current` : ""
                    }
                    onClick={() => {
                      history.push(item.link);
                      setActiveItem(false);
                    }}
                  >
                    {item.i ? <i className={item.i}></i> : ""}
                    <span>{item.span}</span>
                  </a>
                </li>
              ))}
          </ul>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
