import {
  getQuestions,
  addQuestion,
  deleteQuestion,
  answerQuestion,
} from "../../api/questions";

export const CHANGE_QUESTIONS_VALUE = "CHANGE_QUESTIONS_VALUE";
export const CHANGE_QUESTIONS_DATE_VALUE = "CHANGE_QUESTIONS_DATE_VALUE";

export const CHANGE_MODAL_INF = "CHANGE_MODAL_INF";
export const RESET_QUESTIONS_STORE = "RESET_QUESTIONS_STORE";

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_SUCCESS = "GET_QUESTIONS_SUCCESS";
export const GET_QUESTIONS_ERROR = "GET_QUESTIONS_ERROR";

export const GET_LOCATIONS = "GET_LOCATIONS";
export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_ERROR = "GET_LOCATIONS_ERROR";

export const DELETE_QUESTION = "DELETE_QUESTION";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_ERROR = "DELETE_QUESTION_ERROR";

export const ADD_QUESTION = "ADD_QUESTION";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_ERROR = "ADD_QUESTION_ERROR";

export const ANSWER_QUESTION = "ANSWER_QUESTION";
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_ERROR = "ANSWER_QUESTION_ERROR";

export const getQuestionsAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_QUESTIONS });
    try {
      const response = await getQuestions(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch(changeValue("totalResults", results.totalElements));
        dispatch({ type: GET_QUESTIONS_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_QUESTIONS_ERROR });
    }
  };
};

export const deleteQuestionAction = (uuid) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_QUESTION });
    try {
      const response = await deleteQuestion(uuid);
      const { success, results } = response;
      if (success) {
        dispatch({ type: DELETE_QUESTION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: DELETE_QUESTION_ERROR });
    }
  };
};

export const addQuestionAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: ADD_QUESTION });
    try {
      const response = await addQuestion(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: ADD_QUESTION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ADD_QUESTION_ERROR });
    }
  };
};

export const answerQuestionAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: ANSWER_QUESTION });
    try {
      const response = await answerQuestion(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: ANSWER_QUESTION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: ANSWER_QUESTION_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_QUESTIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_QUESTIONS_DATE_VALUE,
  payload: value,
});

export const changeModalValue = (key, value) => ({
  type: CHANGE_MODAL_INF,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_QUESTIONS_STORE,
});
