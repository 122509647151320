import './Search.scss';

export const Search = ({ name, className, value, placeholder, onChange }) => {
  const handleChange = (e) => {
    name ? onChange(name, e.target.value) : onChange(e.target.value);
  };

  return (
    <div className={className}>
      <div className="form-group">
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text bg-white">
              <i className="fas fa-search pink-color"></i>
            </span>
          </div>
          <input
            className="form-control form-control-search"
            name="searchTerm"
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};
