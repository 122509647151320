import request from "../utils/request";

export function getPins(params) {
  return request({
    url: "/order/pins",
    method: "get",
    data: params,
  });
}

export function addPinsMaterial(params) {
  return request({
    url: `/order/pins`,
    method: "post",
    data: params,
  });
}

export function updatePinsMaterial(materialUuid, params) {
  return request({
    url: `/order/pins/material/${materialUuid}`,
    method: "put",
    data: params,
  });
}

export function deletePinsMaterial(materialUuid) {
  return request({
    url: `/order/pins/material/${materialUuid}`,
    method: "delete",
  });
}
