import React from "react";

import * as R from "./constants/routes.constants";

const LoginPage = React.lazy(() => import("./containers/SignIn/Login"));
const SettingsPage = React.lazy(() => import("./containers/Settings/Settings"));
const PackagingPage = React.lazy(() =>
  import("./containers/Packaging/Packaging")
);
const ServicePage = React.lazy(() => import("./containers/Service/Service"));
const HistoryPage = React.lazy(() => import("./containers/History/History"));
const HistoryAnalyticsPage = React.lazy(() =>
  import("./containers/History/Analytics")
);
const ProductionPage = React.lazy(() =>
  import("./containers/Production/Production")
);
const RecHistoryPage = React.lazy(() =>
  import("./containers/RecHistory/RecHistory")
);
const LogsPage = React.lazy(() => import("./containers/Logs/Logs"));
const QuestionsPage = React.lazy(() =>
  import("./containers/Questions/Questions")
);
const SupportHistoryPage = React.lazy(() =>
  import("./containers/SupportHistory/SupportHistory")
);
const TransactionsPage = React.lazy(() =>
  import("./containers/Transactions/Transactions")
);
const CateringPage = React.lazy(() =>
  import("./containers/Transactions/Catering")
);
const TransactionsAnalyticPage = React.lazy(() =>
  import("./containers/Transactions/Analytics")
);
const GetSupportPage = React.lazy(() =>
  import("./containers/GetSupport/GetSupport")
);
const UserProfilePage = React.lazy(() =>
  import("./containers/UserProfile/UserSettings/UserSettings")
);
const ChangePassPage = React.lazy(() =>
  import("./containers/UserProfile/ChangePassword/ChangePassword")
);
const ActiveSessionsPage = React.lazy(() =>
  import("./containers/UserProfile/ActiveSessions/ActiveSessions")
);
const AuthLogPage = React.lazy(() =>
  import("./containers/UserProfile/AuthentificationLog/AuthentificationLog")
);
const MultiFactorPage = React.lazy(() =>
  import("./containers/SignIn/MultiFactorAuth")
);
const DlpsPage = React.lazy(() => import("./containers/Dlps/Dlps"));
const PinsPage = React.lazy(() => import("./containers/Pins/Pins"));

const routes = [
  { id: 1, path: R.LOGIN, component: LoginPage },
  { id: 2, path: R.SERVICE_SIDE, component: ServicePage },
  { id: 3, path: R.PACKAGING_SIDE, component: PackagingPage },
  { id: 4, path: R.SETTINGS, component: SettingsPage },
  { id: 5, path: R.ORDERS_HISTORY, component: HistoryPage },
  { id: 6, path: R.PRODUCTION, component: ProductionPage },
  { id: 7, path: R.REC_HISTORY, component: RecHistoryPage },
  { id: 8, path: R.LOGS, component: LogsPage },
  { id: 9, path: R.QUESTIONS, component: QuestionsPage },
  { id: 10, path: R.AUTHENTIFICATION_LOG, component: AuthLogPage },
  { id: 11, path: R.TRANSACTIONS_INFO, component: TransactionsPage },
  { id: 12, path: R.SUPPORT_HISTORY, component: SupportHistoryPage },
  { id: 13, path: R.GET_SUPPORT, component: GetSupportPage },
  { id: 14, path: R.MULTI_FACTOR_AUTH, component: MultiFactorPage },
  {
    id: 15,
    path: R.TRANSACTIONS_ANALYTIC,
    component: TransactionsAnalyticPage,
  },
  { id: 16, path: R.ORDERS_ANALYTICS, component: HistoryAnalyticsPage },
  { id: 17, path: R.USER_PROFILE, component: UserProfilePage },
  { id: 18, path: R.CHANGE_PASSWORD, component: ChangePassPage },
  { id: 19, path: R.ACTIVE_SESSIONS, component: ActiveSessionsPage },
  { id: 20, path: R.AUTHENTIFICATION_LOG, component: AuthLogPage },
  { id: 21, path: R.DLPS, component: DlpsPage },
  { id: 22, path: R.PINS, component: PinsPage },
  { id: 23, path: R.CATERING, component: CateringPage },
];

export default routes;
