import React from "react";
import "./ButtonPanel.scss";

export const ButtonPanel = ({
  className,
  disabled,
  onClick,
  isLoading = false,
  i,
  customIcon,
  span,
}) => {
  return (
    <div className="col-">
      <div className="form-group">
        <button
          className={`buttonPanel ${className} ${
            disabled || isLoading ? "disabled-button" : ""
          }`}
          disabled={disabled || isLoading}
          onClick={onClick}
        >
          {i ? <i aria-hidden="true" className="fa fa-plus-circle"></i> : ""}
          {customIcon && customIcon}
          {span}
        </button>
      </div>
    </div>
  );
};
