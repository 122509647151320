import {
  checkCode,
  checkRecoveryCode,
  signIn,
  refresh,
  signOut,
} from "../../api/app";
import { changePassword, forgotPassword, getMe } from "../../api/users";

import * as authUtils from "../../utils/authorization";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_RESULT = "LOGIN_USER_RESULT";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";
export const LOGOUT_USER_ERROR = "LOGOUT_USER_ERROR";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_RESULT = "CHANGE_PASSWORD_RESULT";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_RESULT = "FORGOT_PASSWORD_RESULT";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHECK_USER_SECURITY_CODE = "CHECK_USER_SECURITY_CODE";
export const CHECK_USER_SECURITY_CODE_SUCCESS =
  "CHECK_USER_SECURITY_CODE_SUCCESS";
export const CHECK_USER_SECURITY_CODE_ERROR = "CHECK_USER_SECURITY_CODE_ERROR";

export const CHECK_USER_RECOVERY_CODE = "CHECK_USER_RECOVERY_CODE";
export const CHECK_USER_RECOVERY_CODE_SUCCESS =
  "CHECK_USER_RECOVERY_CODE_SUCCESS";
export const CHECK_USER_RECOVERY_CODE_ERROR = "CHECK_USER_RECOVERY_CODE_ERROR";

export const CHANGE_LOGIN_VALUE = "CHANGE_LOGIN_VALUE";
export const GET_ME = "GET_ME";
export const GET_ME_RESULT = "GET_ME_RESULT";
export const GET_ME_ERROR = "GET_ME_ERROR";
export const RESET_LOGIN_STORE = "RESET_LOGIN_STORE";

export const loginUser = (payload, actions = null) => {
  return async (dispatch) => {
    try {
      const { email, password } = payload;
      if (email && password) {
        dispatch({ type: LOGIN_USER });
        const response = await signIn(payload);
        const { success, results } = response;
        if (success) {
          if (
            results.multiFactorAuthentication ||
            results.user?.multiFactorAuthentication
          ) {
            localStorage.setItem("multiFactorAuth", true);
          } else if (!!localStorage.getItem("multiFactorAuth")) {
            localStorage.removeItem("multiFactorAuth");
          }
          authUtils.setAuthDataToLocalStorage(results);
          dispatch({ type: LOGIN_USER_RESULT, payload: results });
          actions?.onSuccess(results);
        }
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGIN_USER_ERROR, message: error.message });
    }
  };
};

export const refreshToken = () => {
  return async (dispatch) => {
    try {
      const response = await refresh();
      const { success, results } = response;
      if (success) {
        authUtils.setAuthDataToLocalStorage(results);
        localStorage.removeItem("isRefreshing");
      }
      return Promise.resolve();
    } catch (error) {
      console.log(error);
      localStorage.removeItem("isRefreshing");
      if (error.code === 401) {
        authUtils.removeAuthDataFromLocalStorage();
        dispatch(resetStore());
        return Promise.reject(error);
      }
      return Promise.resolve();
    }
  };
};

export const logoutUser = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGOUT_USER });
      const response = await signOut();
      const { success, results } = response;
      if (success) {
        authUtils.removeAuthDataFromLocalStorage();
        dispatch(resetStore());
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOGOUT_USER_ERROR });
    }
  };
};

export const resetUser = () => {
  return async (dispatch) => {
    try {
      authUtils.removeAuthDataFromLocalStorage();
      dispatch(resetStore());
    } catch (error) {
      console.log(error);
    }
  };
};

export const checkCodeAction = (params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_USER_SECURITY_CODE });
    try {
      const response = await checkCode(params);
      const { success, results } = response;
      if (success) {
        localStorage.removeItem("multiFactorAuth");
        authUtils.setAuthDataToLocalStorage(results);
        actions?.onSuccess(results);
        dispatch({
          type: CHANGE_LOGIN_VALUE,
          payload: { key: "verified", value: true },
        });
        dispatch({ type: CHECK_USER_SECURITY_CODE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CHECK_USER_SECURITY_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const forgotPass = (payload, actions = {}) => {
  return async (dispatch) => {
    try {
      if (payload) {
        dispatch({ type: FORGOT_PASSWORD });
        const response = await forgotPassword(payload);
        const { success, results } = response;
        if (success) {
          actions.onSuccess();
          dispatch({ type: FORGOT_PASSWORD_RESULT, userData: results.user });
        }
      }
    } catch (error) {
      actions.onError(error.message);
      dispatch({ type: FORGOT_PASSWORD_ERROR, message: error.message });
    }
  };
};

export const changePasswordAction = (payload, actions = {}) => {
  return async (dispatch) => {
    try {
      const { oldPassword, newPassword } = payload;
      if (oldPassword && newPassword) {
        dispatch({ type: CHANGE_PASSWORD });
        const response = await changePassword(payload);
        const { success, results } = response;
        if (success) {
          actions.onSuccess(results);
          dispatch({ type: CHANGE_PASSWORD_RESULT });
        }
      }
    } catch (error) {
      actions.onError(error.message);
      dispatch({ type: CHANGE_PASSWORD_ERROR, message: error.message });
    }
  };
};

export const getMeAction = (actions = {}) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_ME" });
      const response = await getMe();
      const { success, results } = response;
      if (success) {
        localStorage.removeItem("multiFactorAuth");
        dispatch({ type: "GET_ME_RESULT", payload: results });
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ME_ERROR });
      actions.onError && actions.onError();
    }
  };
};

export const checkRecoveryCodeAction = (params, actions = null) => {
  return async (dispatch) => {
    dispatch({ type: CHECK_USER_RECOVERY_CODE });
    try {
      const response = await checkRecoveryCode(params);
      const { success, results } = response;
      if (success) {
        localStorage.removeItem("multiFactorAuth");
        authUtils.setAuthDataToLocalStorage(results);
        actions?.onSuccess(results);
        dispatch({
          type: CHANGE_LOGIN_VALUE,
          payload: { key: "verified", value: true },
        });
        dispatch({ type: CHECK_USER_RECOVERY_CODE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: CHECK_USER_RECOVERY_CODE_ERROR });
      actions?.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_LOGIN_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_LOGIN_STORE,
});
