import request from '../utils/request';

export function getSupport(params) {
  return request({
    url: `/support`,
    method: 'get',
    data: params,
  });
}

export function sendSupport(params) {
  return request({
    url: `/support/support`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: params,
  });
}
