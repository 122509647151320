import React from "react";
import PropTypes from "prop-types";

export const HeaderCell = ({
  label,
  align,
  value,
  sortable,
  active,
  direction,
  width,
  onSortClick,
}) => {
  const handleClick = () => {
    if (active) {
      onSortClick("orderDirection", direction === "DESC" ? "ASC" : "DESC");
    } else {
      onSortClick("sortBy", value);
      onSortClick("orderDirection", "DESC");
    }
  };

  return (
    <th
      className={`${sortable ? "sortable-cell" : ""}`}
      onClick={sortable ? handleClick : null}
      align={align ? align : "left"}
      style={{ width: width || "auto" }}
    >
      {label}
      {active ? (
        <span
          className={`${
            direction === "DESC"
              ? "sortable-cell__indicator-down"
              : "sortable-cell__indicator-up"
          }`}
        />
      ) : null}
    </th>
  );
};

HeaderCell.propTypes = {
  label: PropTypes.string,
  align: PropTypes.string,
  onSortClick: PropTypes.func,
};
