export const setAuthDataToLocalStorage = (data) => {
  const authData = {
    userUuid: data.user?.uuid,
    accessToken: data.token,
    refreshToken: data.refreshToken,
    accessExpDate: data.expToken,
    refreshExpDate: data.expRToken,
  };

  localStorage.setItem("authData", JSON.stringify(authData));
};

export const getAuthDataFromLocalStorage = () => {
  const authData = JSON.parse(localStorage.getItem("authData"));
  return authData;
};

export const removeAuthDataFromLocalStorage = () => {
  localStorage.removeItem("authData");
};

export const getAccessToken = () => {
  const authData = JSON.parse(localStorage.getItem("authData"));

  if (authData && authData.accessToken) {
    return authData.accessToken;
  }

  return null;
};

export const checkStorageChanges = (event, callbacks = {}) => {
  if (event.key === "authData") {
    if (!event.newValue) {
      callbacks.resetUser();
    } else {
      window.location.reload();
    }
  }
};
