import { useEffect, useState } from 'react';
import './Dropdown.scss';

export const Dropdown = ({ title, id = 'id', dropdownItems, position = '' }) => {
  const [open, toggleOpen] = useState(false);

  useEffect(() => {
    if (open) {
      document.addEventListener('click', checkClick);
    }
  }, [open]);

  function checkClick(e) {
    if (!(e.path || (e.composedPath && e.composedPath())).includes(document.getElementById(`${id}-ul`))) {
      toggleOpen(false);
      return document.removeEventListener('click', checkClick);
    }
  }

  return (
    <div
      className="dropdown"
      onClick={(e) => {
        toggleOpen((prev) => !prev);
      }}
    >
      {title}
      <ul
        className={open ? `dropdown-menu show ${position}` : `dropdown-menu `}
        aria-labelledby={id}
        id={`${id}-ul`}
      >
        {dropdownItems.map((item) => (
          <li>
            <a
              className="dropdown-item"
              onClick={() => {
                item.func();
              }}
            >
              <span>{item.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};
