import { getProduction, submitProduction } from "../../api/production";
import { statusOrder } from "../../api/settings";

export const CHANGE_PRODUCTION_VALUE = "CHANGE_PRODUCTION_VALUE";
export const RESET_PRODUCTION_STORE = "RESET_PRODUCTION_STORE";

export const GET_PRODUCTION = "GET_PRODUCTION";
export const GET_PRODUCTION_SUCCESS = "GET_PRODUCTION_SUCCESS";
export const GET_PRODUCTION_ERROR = "GET_PRODUCTION_ERROR";

export const SUBMIT_ORDER = "SUBMIT_ORDER";

export const getProductionAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_PRODUCTION });
    try {
      const response = await getProduction(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch(changeValue("totalResults", results.numberOfElements));
        dispatch({ type: GET_PRODUCTION_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PRODUCTION_ERROR });
    }
  };
};

export const getStatus = (setData) => {
  return async (dispatch) => {
    try {
      const response = await statusOrder();
      const { success, results } = response;
      if (success) {
        setData(results.status);
        dispatch(
          changeValue(
            "testTime",
            results.testTime
              ? `${results.testTime[0]
                  .toString()
                  .padStart(2, "0")}:${results.testTime[1]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
        dispatch(
          changeValue(
            "startTime",
            results.startTime
              ? `${results.startTime[3]
                  .toString()
                  .padStart(2, "0")}:${results.startTime[4]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const submitProductionAction = (uuid) => {
  return async (dispatch) => {
    try {
      const response = await submitProduction(uuid);
      if (response) {
        dispatch({ type: SUBMIT_ORDER });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_PRODUCTION_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_PRODUCTION_STORE,
});
