import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { intervals, timeIntervals } from "../../constants/analytics.constants";

import "./Analytics.scss";
import moment from "moment";

export const SmallBarChart = ({
  isLoading,
  graphContent,
  customColors = false,
  customOptions = {},
  chartHeader,
  dates,
  sidebar,
  clickBar,
  changeInterval,
  timeLimit,
}) => {
  const [resize, setResize] = useState("right");

  useEffect(() => {
    setTimeout(function () {
      setResize(sidebar ? "right" : "left");
    }, 500);
  }, [sidebar]);

  useEffect(() => {
    getInterval();
  }, [dates[0], dates[2], dates[3]]);

  const getInterval = () => {
    if (
      (dates[0] !== null &&
        dates[0] !== "select" &&
        dates[1] !== null &&
        dates[1] !== "select") ||
      dates[2] !== null
    ) {
      let searchInterval = timeIntervals.find(
        (time) =>
          time.value ===
          parseInt(dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000)
      );
      if (searchInterval) {
        setTimeout(
          () => changeInterval(intervals[searchInterval.auto].value),
          0
        );
      } else {
        let searchCalendar = timeIntervals
          .slice(0, timeIntervals.length - 1)
          .find(
            (time, index) =>
              time.value >=
                parseInt(
                  dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000
                ) &&
              parseInt((dates[1] - dates[0]) / 1000) <=
                timeIntervals[index + 1].value
          );
        if (searchCalendar) {
          setTimeout(
            () => changeInterval(intervals[searchCalendar.auto].value),
            0
          );
        } else {
          setTimeout(
            () =>
              changeInterval(
                intervals[timeIntervals[timeIntervals.length - 1].auto].value
              ),
            0
          );
        }
      }
    }
  };

  return (resize &&
    graphContent &&
    graphContent.length !== 0 &&
    graphContent.reduce(function (sum, elem) {
      return (
        sum +
        (Number(
          elem.slice(1).reduce(function (summ, el) {
            return summ + Number(el);
          }, 0)
        ) || 0)
      );
    }, 0) > 0) ||
    isLoading ? (
    <div className="small-bar--chart">
      {!isLoading ? (
        graphContent !== "No information" ? (
          <Chart
            chartType={"ColumnChart"}
            data={[
              [
                "Timestamp",
                { type: "string", role: "tooltip", p: { html: true } },
                ...chartHeader,
              ],
              ...(graphContent.length !== 0 &&
              graphContent[0].length === chartHeader.length + 1
                ? graphContent.map((graph) => [
                    graph[0],
                    `<div style="width: max-content; flex-wrap: nowrap; display: flex; flex-direction: column; padding: 5px 5px 5px 5px; font-size: 14px; line-height: 1.2;"><b>${moment(
                      graph[0]
                    ).format("MMM D, YYYY, HH:mm:ss")}</b>${`${graph
                      .slice(1)
                      .map((content, index) => [chartHeader[index], content])
                      .sort((x, y) => {
                        return y[1] - x[1];
                      })
                      .map(
                        (content, index) =>
                          `<div>${content[0]}: <b>${content[1]}</b></div>`
                      )}`.replaceAll(",", "")}</div>`,
                    ...graph.slice(1),
                  ])
                : [[0, "", ...chartHeader.map((header) => 0)]]),
            ]}
            chartEvents={[
              {
                eventName: "ready",
                callback: ({ chartWrapper, google }) => {
                  const chart = chartWrapper.getChart();
                  google.visualization.events.addListener(
                    chart,
                    "select",
                    () => {
                      let selection = chart.getSelection();
                      if (
                        selection &&
                        selection.length !== 0 &&
                        selection[0] &&
                        selection[0].length !== 0 &&
                        selection[0].row !== null
                      ) {
                        let select = selection[0].row;
                        if (
                          graphContent[select] &&
                          (graphContent[select + 1]
                            ? graphContent[select + 1][0].getTime()
                            : new Date(
                                dates[2] !== null ? Date.now() : dates[1]
                              ).getTime()) -
                            graphContent[select][0].getTime() >
                            timeLimit
                        ) {
                          let oldStartDate = dates[0];
                          clickBar(
                            graphContent[select][0].getTime(),
                            graphContent[select + 1]
                              ? graphContent[select + 1][0].getTime()
                              : dates[2] !== null
                              ? Date.now()
                              : new Date(dates[1]).getTime(),
                            0
                          );

                          if (
                            oldStartDate === graphContent[select][0].getTime()
                          ) {
                            getInterval();
                          }
                        }
                      }
                    }
                  );
                },
              },
            ]}
            options={{
              animation: { startup: true, duration: 1000, easing: "out" },
              focusTarget: "category",
              tooltip: { isHtml: true },
              colors: customColors ? customColors : null,
              legend: { position: "none" },
              isStacked: true,
              bar: { groupWidth: "75%" },
              width: "100%",
              height: "100%",
              hAxis: {
                minValue: new Date(
                  Math.floor(
                    dates[2] !== null ? Date.now() - dates[2] * 1000 : dates[0]
                  )
                ),
                gridlines: {
                  color: "none",
                  units: {
                    minutes: {
                      format: ["HH:mm"],
                    },
                    hours: {
                      format: ["HH:mm"],
                    },
                    days: {
                      format: ["MM/dd"],
                    },
                    months: {
                      format: ["MM/YYYY"],
                    },
                    years: {
                      format: ["YYYY"],
                    },
                  },
                },
                minorGridlines: {
                  count: 0,
                  units: {
                    minutes: {
                      format: ["HH:mm"],
                    },
                    hours: {
                      format: ["HH:mm"],
                    },
                    days: {
                      format: ["MM/dd"],
                    },
                    months: {
                      format: ["MM/YYYY"],
                    },
                    years: {
                      format: ["YYYY"],
                    },
                  },
                },
              },
              vAxis: {
                gridlines: {
                  count: 4,
                },
                minorGridlines: {
                  count: 0,
                },
                viewWindow: {
                  min: 0,
                  max:
                    Math.max(
                      ...(graphContent
                        .map((item) =>
                          item.slice(1).reduce(function (sum, elem) {
                            return sum + Number(elem);
                          }, 0)
                        )
                        .flat() || 100)
                    ) * 1.3,
                },
                minValue: 0,
                maxValue:
                  Math.max(
                    ...(graphContent
                      .map((item) =>
                        item.reduce(function (sum, elem) {
                          return sum + Number(elem);
                        }, 0)
                      )
                      .flat() || 100)
                  ) * 1.3,
              },
              chartArea: { width: "93%", height: "60%" },
              ...customOptions,
            }}
          />
        ) : (
          <div className="small-bar--chart-error">No information</div>
        )
      ) : (
        <div className="small-bar--loader">
          <FontAwesomeIcon icon={faSpinner} spin />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};
