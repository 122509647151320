import {
  getSupport,
  addSupport,
  deleteSupport,
  editSupport,
  getSupportHistory,
} from "../../api/support";

export const CHANGE_SUPPORT_HISTORY_VALUE = "CHANGE_SUPPORT_HISTORY_VALUE";
export const CHANGE_SUPPORT_HISTORY_DATE_VALUE =
  "CHANGE_SUPPORT_HISTORY_DATE_VALUE";
export const RESET_SUPPORT_HISTORY_STORE = "RESET_SUPPORT_HISTORY_STORE";

export const GET_SUPPORT_HISTORY = "GET_SUPPORT_HISTORY";
export const GET_SUPPORT_HISTORY_SUCCESS = "GET_SUPPORT_HISTORY_SUCCESS";
export const GET_SUPPORT_HISTORY_ERROR = "GET_SUPPORT_HISTORY_ERROR";

export const getSupportHistoryAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_SUPPORT_HISTORY });
    try {
      const response = await getSupportHistory(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch(changeValue("totalResults", results.total));
        dispatch({ type: GET_SUPPORT_HISTORY_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SUPPORT_HISTORY_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SUPPORT_HISTORY_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_SUPPORT_HISTORY_DATE_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_SUPPORT_HISTORY_STORE,
});
