import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";

import { HeaderCell } from "../NewTable/HeaderCell";
import { Pages, Checkbox, Preloader, CursorPages, TablePreloader } from "..";

import "./NewTable.scss";

export const NewTable = ({
  rows,
  title,
  headerOptions,
  toolbarElements,
  isLoading,
  filters,
  time,
  status,
  active,
  table,
  disabled,
  disableWrapperPadding,
  sidebar,
  totalRows,
  perPage,
  settings,
  isLoadingPage = false,
  changeFilterValue,
  cursor,
  scroll = false,
  nextData,
  existsNextPage = false,
}) => {
  useEffect(() => {
    if (scroll && rows.length > 0 && !isLoading && !filters.scrollLoading) {
      let scrollHeight = document.getElementById("table__wrapper").clientHeight;
      let pageHeight =
        document.getElementById("scrollableDiv").clientHeight - 100;
      if (
        pageHeight &&
        scrollHeight &&
        pageHeight >= scrollHeight &&
        ((totalRows && rows.length < totalRows) || existsNextPage)
      ) {
        nextData();
      }
    }
  }, [rows]);

  return (
    <div
      id="scrollableDiv"
      className={cx("table__container", {
        "table__container--disabled": disabled,
        "table__container--no--padding": disableWrapperPadding,
        "table__container--right-indent": sidebar,
        "table__container--with-setting": settings,
      })}
    >
      {isLoadingPage && <Preloader />}
      <div className={settings ? "settings__block" : "table__block"}>
        <div
          className={`table__header ${
            settings ? "table__header--settings" : ""
          }`}
        >
          <p className="table__title page-title">{title}</p>
          {status && (
            <p
              className={`table__title page-title ${
                status === "ACTIVE" ? "active-status" : "inactive-status"
              }`}
            >
              {status}
            </p>
          )}
          {status && time && (
            <div className="time-block">
              <span className="clock-span">Test Start Time: {time[0]} </span>
              {status && status === "ACTIVE" ? (
                <span className="clock-span">
                  Current Test Time: {time[1].substr(0, 5)}{" "}
                </span>
              ) : (
                <span className="clock-span">
                  Current Test Time: {time[0]}{" "}
                </span>
              )}
            </div>
          )}
        </div>
        {toolbarElements && (
          <div className="table__toolbar">{toolbarElements}</div>
        )}
        {table &&
          (scroll ? (
            <InfiniteScroll
              dataLength={rows.length}
              next={() => nextData()}
              hasMore={
                (totalRows && rows.length && rows.length < totalRows) ||
                (existsNextPage && !isLoading)
              }
              scrollableTarget="scrollableDiv"
              scrollThreshold={1}
              loader={<TablePreloader big={true} />}
              style={{ overflowY: "hidden" }}
            >
              <div id="table__wrapper" className="table__wrapper">
                <div className="table__wrapper__inner">
                  <table
                    className={`table__table ${
                      isLoading ? "table--loading" : ""
                    }`}
                  >
                    <thead>
                      <tr>
                        {headerOptions.map((cell, index) => {
                          const { label, value, align, sortable, width } = cell;
                          return (
                            <HeaderCell
                              key={label ? label : index}
                              label={label}
                              value={value}
                              sortable={sortable}
                              active={value === filters.sortBy}
                              direction={filters.orderDirection}
                              align={align}
                              width={width}
                              onSortClick={changeFilterValue}
                            />
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading ? (
                        <tr>
                          <td colSpan="20" className="text-muted text-center">
                            <i className="fa fa-spinner fa-spin fa-2x"></i>
                          </td>
                        </tr>
                      ) : rows.length !== 0 ? (
                        rows.map((row, index) => {
                          const {
                            id,
                            cells,
                            opened,
                            fullInfo,
                            loading,
                            openedTableCells,
                          } = row;
                          return (
                            <>
                              <tr
                                key={id}
                                className={
                                  id && id !== "" && active === id
                                    ? "active-tr"
                                    : ""
                                }
                              >
                                {cells.map((cell) => {
                                  const {
                                    label,
                                    align,
                                    padding,
                                    color,
                                    width,
                                    font,
                                    cellComponent,
                                  } = cell;
                                  return (
                                    <td
                                      id={index}
                                      align={align ? align : "left"}
                                      style={{
                                        fontSize: font ? font : "",
                                        padding: padding ? 5 : "",
                                        color: color ? color : "",
                                        width: width ? width : "",
                                      }}
                                    >
                                      {cellComponent
                                        ? cellComponent
                                        : label || ""}
                                    </td>
                                  );
                                })}
                              </tr>
                              {opened && (
                                <tr
                                  className="tr__uncolored"
                                  key={`opened-${id}`}
                                >
                                  <td
                                    colSpan={headerOptions.length + 1}
                                    className="opened--list"
                                  >
                                    <table className="table__table">
                                      <thead>
                                        <tr>
                                          {openedTableCells.map(
                                            (cell, index) => {
                                              return (
                                                <HeaderCell
                                                  key={index}
                                                  label={cell.label}
                                                  value={cell.value}
                                                  sortable={false}
                                                />
                                              );
                                            }
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {loading ? (
                                          <tr>
                                            <td
                                              colSpan="20"
                                              className="text-muted text-center"
                                            >
                                              <i className="fa fa-spinner fa-spin fa-2x"></i>
                                            </td>
                                          </tr>
                                        ) : fullInfo.length !== 0 ? (
                                          fullInfo.map((item, index) => (
                                            <tr key={index}>
                                              {openedTableCells.map(
                                                (tableCell) => (
                                                  <td id={index} align={"left"}>
                                                    {item[tableCell.value]}
                                                  </td>
                                                )
                                              )}
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="20"
                                              className="text-muted text-center"
                                            >
                                              No records
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="20" className="text-muted text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </InfiniteScroll>
          ) : (
            <div className="table__wrapper">
              <div className="table__wrapper__inner">
                <table
                  className={`table__table ${
                    isLoading ? "table--loading" : ""
                  }`}
                >
                  <thead>
                    <tr>
                      {headerOptions.map((cell, index) => {
                        const { label, value, align, sortable, width } = cell;
                        return (
                          <HeaderCell
                            key={label ? label : index}
                            label={label}
                            value={value}
                            sortable={sortable}
                            active={value === filters.sortBy}
                            direction={filters.orderDirection}
                            align={align}
                            width={width}
                            onSortClick={changeFilterValue}
                          />
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan="20" className="text-muted text-center">
                          <i className="fa fa-spinner fa-spin fa-2x"></i>
                        </td>
                      </tr>
                    ) : rows.length !== 0 ? (
                      rows.map((row, index) => {
                        const {
                          id,
                          cells,
                          opened,
                          fullInfo,
                          loading,
                          openedTableCells,
                        } = row;
                        return (
                          <>
                            <tr
                              key={id}
                              className={
                                id && id !== "" && active === id
                                  ? "active-tr"
                                  : ""
                              }
                            >
                              {cells.map((cell) => {
                                const {
                                  label,
                                  align,
                                  padding,
                                  color,
                                  width,
                                  font,
                                  cellComponent,
                                } = cell;
                                return (
                                  <td
                                    id={index}
                                    align={align ? align : "left"}
                                    style={{
                                      fontSize: font ? font : "",
                                      padding: padding ? 5 : "",
                                      color: color ? color : "",
                                      width: width ? width : "",
                                    }}
                                  >
                                    {cellComponent
                                      ? cellComponent
                                      : label || ""}
                                  </td>
                                );
                              })}
                            </tr>
                            {opened && (
                              <tr
                                className="tr__uncolored"
                                key={`opened-${id}`}
                              >
                                <td
                                  colSpan={headerOptions.length + 1}
                                  className="opened--list"
                                >
                                  <table className="table__table">
                                    <thead>
                                      <tr>
                                        {openedTableCells.map((cell, index) => {
                                          return (
                                            <HeaderCell
                                              key={index}
                                              label={cell.label}
                                              value={cell.value}
                                              sortable={false}
                                            />
                                          );
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {loading ? (
                                        <tr>
                                          <td
                                            colSpan="20"
                                            className="text-muted text-center"
                                          >
                                            <i className="fa fa-spinner fa-spin fa-2x"></i>
                                          </td>
                                        </tr>
                                      ) : fullInfo.length !== 0 ? (
                                        fullInfo.map((item, index) => (
                                          <tr key={index}>
                                            {openedTableCells.map(
                                              (tableCell) => (
                                                <td id={index} align={"left"}>
                                                  {item[tableCell.value]}
                                                </td>
                                              )
                                            )}
                                          </tr>
                                        ))
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="20"
                                            className="text-muted text-center"
                                          >
                                            No records
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="20" className="text-muted text-center">
                          No records
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {cursor ? (
                <CursorPages
                  perPage={filters.limit}
                  existNext={filters.existNext}
                  existPrev={filters.existPrevious}
                  cursorDirection={filters.cursorDirection}
                  onChangePage={(value) => {
                    if (!isLoading) {
                      changeFilterValue("cursorDirection", value);
                      changeFilterValue("changePage", true);
                    }
                  }}
                  changePerPage={(value) => changeFilterValue("limit", value)}
                />
              ) : totalRows && totalRows !== 0 && !isLoading ? (
                <Pages
                  totalRows={totalRows}
                  page={filters.page}
                  perPage={perPage ? perPage : 20}
                  onChangePage={(value) => {
                    changeFilterValue("page", value);
                  }}
                  changePerPage={(value) => changeFilterValue("size", value)}
                />
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

NewTable.propTypes = {
  rows: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  headerOptions: PropTypes.array,
  isLoading: PropTypes.bool,
  filters: PropTypes.object,
  totalRows: PropTypes.number.isRequired,
  onChangeParams: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  disableWrapperPadding: PropTypes.bool,
  buttonComponent: PropTypes.element,
  sidebar: PropTypes.bool,
};
