import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import "./preloader.scss";

export const TablePreloader = ({ big }) => (
  <div className="w-100 d-flex justify-content-center">
    <FontAwesomeIcon
      icon={faSpinner}
      className={big ? "big--spinner" : ""}
      spin
    />
  </div>
);
