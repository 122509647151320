import { getPackaging, submitOrder } from "../../api/packaging";
import { statusOrder } from "../../api/settings";

export const CHANGE_PACKAGING_VALUE = "CHANGE_PACKAGING_VALUE";
export const RESET_PACKAGING_STORE = "RESET_PACKAGING_STORE";

export const GET_PACKAGING = "GET_PACKAGING";
export const GET_PACKAGING_SUCCESS = "GET_PACKAGING_SUCCESS";
export const GET_PACKAGING_ERROR = "GET_PACKAGING_ERROR";

export const SUBMIT_ORDER = "SUBMIT_ORDER";

export const getPackagingAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_PACKAGING });
    try {
      const response = await getPackaging(params);
      const { success, results } = response;
      if (success) {
        setData([...results].filter((item) => item.uuid !== null));
        dispatch(changeValue("totalResults", results.total));
        dispatch({ type: GET_PACKAGING_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PACKAGING_ERROR });
    }
  };
};

export const getStatus = (setData) => {
  return async (dispatch) => {
    try {
      const response = await statusOrder();
      const { success, results } = response;
      if (success) {
        setData(results.status);
        dispatch(
          changeValue(
            "testTime",
            results.testTime
              ? `${results.testTime[0]
                  .toString()
                  .padStart(2, "0")}:${results.testTime[1]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
        dispatch(
          changeValue(
            "startTime",
            results.startTime
              ? `${results.startTime[3]
                  .toString()
                  .padStart(2, "0")}:${results.startTime[4]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const submitOrderAction = (uuid, params) => {
  return async (dispatch) => {
    try {
      const response = await submitOrder(uuid, params);
      if (response) {
        dispatch({ type: SUBMIT_ORDER });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_PACKAGING_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_PACKAGING_STORE,
});
