import * as types from "../actions/dlpsAction";

const initialState = {
  loading: false,
  totalResults: 0,
  page: 0,
  size: "20",
  route: "Breading",
  date: null,
  logLevel: null,
  data: null,
};

const dlpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_DLPS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_DLPS_DATE_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_DLPS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_DLPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.GET_DLPS_ERROR:
      return {
        ...state,
        loading: false,
        data: null,
        errorMessage: action.message,
      };
    case types.RESET_DLPS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default dlpsReducer;
