import * as types from '../actions/sessionsAction';

const initialState = {
  data: [],
  startDate: 'select',
  endDate: 'select',
  orderDirection: 'DESC',
  sortBy: 'signedInTime',
  limit: '20',
  page: 0,

  totalResults: 0,
  loading: false,
};

const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SESSION_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.GET_SESSIONS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_SESSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalResults: action.payload.length,
      };

    case types.GET_SESSIONS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_SESSION_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default sessionsReducer;
