import NavTab from "./NavTab";
import "./NavTabs.scss";

export const NavTabs = ({ name, navTabs, activeTab, className, onChange }) => {
  const handleChange = (value) => {
    name ? onChange(name, value) : onChange(value);
  };

  return (
    <ul className={`nav ${className}`} role="tablist">
      {navTabs.map((tab, index) => (
        <NavTab
          key={index}
          i={tab.i}
          span={tab.label}
          active={tab.value === activeTab}
          onClick={() => handleChange(tab.value)}
        />
      ))}
    </ul>
  );
};
