import React, { useEffect, useMemo, useRef, useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import cx from "classnames";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./Datepicker.scss";
const DROPDOWN_PANEL_WIDTH = 350;

export const SingleDatePicker = ({
  placeholder,
  className,
  minDate,
  maxDate,
  date,
  onChange,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [openMode, setOpenMode] = useState("left");

  const fieldRef = useRef(null);
  const dropdownPanelRef = useRef(null);

  useEffect(() => {
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("resize", checkOpenMode);
    checkOpenMode();

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("resize", checkOpenMode);
    };
  }, []);

  useEffect(() => {
    isOpen && checkOpenMode();
  }, [isOpen]);

  const handleMouseDown = (event) => {
    if (fieldRef.current && !fieldRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const checkOpenMode = () => {
    if (!fieldRef.current) setOpenMode("left");
    const fieldWidth = fieldRef.current.clientWidth;

    fieldWidth < DROPDOWN_PANEL_WIDTH
      ? setOpenMode("right")
      : setOpenMode("left");
  };

  const handleChangeDate = (value) => {
    const newDate = moment(value).format("YYYY-MM-DD");
    onChange(newDate);
    setOpen(false);
  };

  return (
    <div
      ref={fieldRef}
      className={cx("date-picker__container", className)}
      onClick={(e) => setOpen(true)}
    >
      <div className="form-group">
        <div className="date-picker__input">
          <div
            className="date-picker__icon"
            onClick={(e) => {
              e.stopPropagation();
              setOpen((prev) => !prev);
            }}
          >
            <FontAwesomeIcon icon={faCalendarDay} />
          </div>
          <input
            type="text"
            placeholder={placeholder}
            readOnly={true}
            value={date ? moment(date).format("YYYY MMM D") : null}
          />
        </div>
      </div>
      {isOpen && (
        <div
          ref={dropdownPanelRef}
          className={`date-picker__dropdown-panel ${openMode}`}
        >
          <Calendar
            className=""
            showDateDisplay={false}
            date={date ? new Date(date) : null}
            onChange={handleChangeDate}
            minDate={minDate ? minDate : new Date("2019-01-01")}
            maxDate={maxDate ? maxDate : new Date()}
          />
        </div>
      )}
    </div>
  );
};
