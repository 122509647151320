import { useRef } from "react";
import { useHistory } from "react-router-dom";

const SidebarItem = ({
  onClick,
  link,
  i,
  span,
  disabled,
  tooltipRef,
  active,
  activeItem,
  setActiveItem,
}) => {
  const history = useHistory();
  const liRef = useRef(null);

  const handleMouseOver = (event) => {
    if (
      document.getElementById(
        event.target.id || event.nativeEvent?.fromElement?.id
      ) &&
      (event.target.id || event.nativeEvent?.fromElement?.id) === link
    )
      activeItem !== span &&
        setActiveItem({
          span: span,
          top:
            document
              .getElementById(
                event.target?.id || event.nativeEvent?.fromElement?.id
              )
              ?.getBoundingClientRect().top - 76 || 0,
        });
  };

  const handleMouseLeave = () => {
    setTimeout(function () {
      if (
        liRef.current?.parentElement.querySelector(":hover")?.className !==
          liRef.current?.className &&
        tooltipRef.current?.parentElement.querySelector(":hover") !==
          tooltipRef.current
      )
        setActiveItem(false);
    }, 500);
  };

  return (
    <li
      className="sidebar--item-li"
      ref={liRef}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      <a
        to={link}
        id={link}
        className={disabled ? "disabled" : active ? `current` : ""}
        // onClick={onClick}
        onClick={() => {
          typeof onClick === "function"
            ? onClick(link, span)
            : history.push(link);
        }}
      >
        <i className={i}></i>
        <span>{span}</span>
      </a>
    </li>
  );
};

export default SidebarItem;
