import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import moment from "moment";
import { faCalendar } from "@fortawesome/free-regular-svg-icons";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

import "./Datepicker.scss";
import { getRelativeDate, modifyAddress } from "../../utils";

const intervalsDate = [
  {
    icon: <FontAwesomeIcon icon={faXmark} />,
    label: "Select Date",
    value: "select",
  },
  { icon: "15m", label: "Past 15 Minutes", value: 900 },
  { icon: "1h", label: "Past 1 Hour", value: 3600 },
  { icon: "4h", label: "Past 4 Hours", value: 14400 },
  { icon: "1d", label: "Past 1 Day", value: 86400 },
  { icon: "2d", label: "Past 2 Days", value: 172800 },
  { icon: "3d", label: "Past 3 Days", value: 259200 },
  { icon: "7d", label: "Past 7 Days", value: 604800 },
  { icon: "15d", label: "Past 15 Days", value: 1296000 },
  {
    icon: "1mo",
    label: "Past 1 Month",
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() ===
        (new Date().getMonth() - 1 < 0
          ? 12 + (new Date().getMonth() - 1)
          : new Date().getMonth() - 1)
          ? new Date().setMonth(new Date().getMonth() - 1)
          : new Date(new Date().getFullYear(), new Date().getMonth(), 0))) /
        1000
    ),
  },
  {
    icon: "6mo",
    label: "Past 6 Months",
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 6)).getMonth() ===
        (new Date().getMonth() - 6 < 0
          ? 12 + (new Date().getMonth() - 6)
          : new Date().getMonth() - 6)
          ? new Date().setMonth(new Date().getMonth() - 6)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 5, 0))) /
        1000
    ),
  },
  {
    icon: "1y",
    label: "Past 1 Year",
    value: Math.floor(
      (Date.now() - new Date().setFullYear(new Date().getFullYear() - 1)) / 1000
    ),
  },
];

export const Datepicker = ({
  history = null,
  removeSelect = false,
  selectMax = 0,
  calendarMax = null,
  value,
  onChange,
  end,
  timeInterval,
  defaultValue = null,
  changedSearch,
  setChangedSearch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [selectOpened, setSelectOpened] = useState(false);
  const [time, setTime] = useState([]);
  const [calendarState, setCalendarState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  useEffect(() => {
    if (changedSearch) {
      let search = history.location.search
        .slice(1)
        .split("&")
        .filter(
          (value) =>
            value.includes("startDate") ||
            value.includes("endDate") ||
            value.includes("timeInterval")
        );
      if (
        search.length === 0 &&
        value !== "select" &&
        Date.now() - 100 > (end !== null && end !== "select" ? end : 0) &&
        (defaultValue === null ||
          intervalsDate.find((interv) => interv.icon === defaultValue).value !==
            timeInterval)
      ) {
        onChange(
          defaultValue !== null
            ? intervalsDate.find((interv) => interv.icon === defaultValue).value
            : "select",
          defaultValue !== null ? null : "select"
        );
      } else if (
        search.some((val) =>
          val.split("=")[0] === "timeInterval"
            ? timeInterval === null ||
              intervalsDate.find((interv) => interv.value === timeInterval)
                .icon !== Number(val.split("=")[1])
            : val.split("=")[0] === "startDate"
            ? val.split("=")[1] !== value
            : val.split("=")[1] !== end
        )
      ) {
        checkAddressLine();
      }
      setChangedSearch(false);
    } else if (
      defaultValue !== null &&
      !history.location.search.includes("startDate") &&
      !history.location.search.includes("timeInterval") &&
      value === null &&
      end === null &&
      timeInterval === null
    ) {
      setTimeout(
        () =>
          onChange(
            intervalsDate.find((interv) => interv.icon === defaultValue).value,
            null
          ),
        0
      );
    }
  }, [changedSearch]);

  useEffect(() => {
    if (selectOpened || isOpen) {
      document.addEventListener("click", checkClick);
    }
  }, [selectOpened, isOpen]);

  useEffect(() => {
    if (
      !isNaN(
        Date.parse(calendarState[0].endDate) &&
          calendarState[0].endDate !== null
      )
    ) {
      if (calendarMax !== null) {
        calendarState[0].endDate.getTime() -
          calendarState[0].startDate.getTime() +
          86340000 >
        calendarMax
          ? NotificationManager.error(
              `Date range must be 
              less than ${getRelativeDate(Date.now() - calendarMax)}`.replace(
                "ago",
                ""
              ),
              "Error",
              3000
            )
          : onChangeAction(
              ((calendarState[0].endDate
                ? calendarState[0].endDate.getTime() + 86340000
                : Date.now()) -
                new Date(calendarState[0].startDate).getTime()) /
                1000,
              new Date(calendarState[0].endDate).getTime() + 86340000 <
                Date.now()
                ? new Date(calendarState[0].endDate).getTime() + 86340000
                : Date.now()
            );
      } else {
        onChangeAction(
          ((calendarState[0].endDate &&
          new Date(calendarState[0].endDate).getTime() + 86340000 < Date.now()
            ? new Date(calendarState[0].endDate).getTime() + 86340000
            : Date.now()) -
            new Date(
              calendarState[0].startDate.setHours(0, 0, 0, 0)
            ).getTime()) /
            1000,
          new Date(calendarState[0].endDate).getTime() + 86340000 < Date.now()
            ? new Date(calendarState[0].endDate).getTime() + 86340000
            : Date.now()
        );
        setOpen(false);
        setCalendarState([
          {
            startDate: new Date(),
            endDate: null,
            key: "selection",
          },
        ]);
      }
    }
  }, [calendarState]);

  useEffect(() => {
    if (selectOpened) {
      if (document.getElementById("datepicker_input") !== null) {
        document.getElementById("datepicker_input").select();
      }
    } else {
      if (time.length !== 0) {
        changeDatepickerTime();
      }
    }
  }, [selectOpened]);

  const checkAddressLine = () => {
    if (
      history.location.search.length !== 0 &&
      history.location.search.includes("startDate")
    ) {
      let search = history.location.search
        .slice(1)
        .split("&")
        .filter(
          (value) => value.includes("startDate") || value.includes("endDate")
        )
        .map((date) =>
          date.includes("startDate")
            ? Number(date.slice(10))
            : Number(date.slice(8))
        );
      if (
        search.length === 2 &&
        new Date(search[0]) &&
        new Date(
          search[1] &&
            Number(search[0]) < Date.now() &&
            Number(search[1]) < Date.now()
        )
      ) {
        if (search[0] !== value || search[1] !== end) {
          setTimeout(
            () =>
              onChange(Math.floor((search[1] - search[0]) / 1000), search[1]),
            0
          );
        }
      } else
        history.push(
          history.location.pathname +
            modifyAddress(history.location.search, ["startDate", "endDate"])
        );
    } else if (
      history.location.search.length !== 0 &&
      history.location.search.includes("timeInterval")
    ) {
      let interval = history.location.search
        .slice(1)
        .split("&")
        .find((value) => value.includes("timeInterval"))
        .slice(13);
      if (intervalsDate.find((interv) => interv.icon === interval)) {
        if (
          timeInterval !==
          intervalsDate.find((interv) => interv.icon === interval).value
        ) {
          setTimeout(
            () =>
              onChange(
                intervalsDate.find((interv) => interv.icon === interval).value,
                null
              ),
            0
          );
        }
      } else
        history.push(
          history.location.pathname +
            modifyAddress(history.location.search, ["timeInterval"])
        );
    }
  };

  const onChangeAction = (startDate, endDate = null) => {
    onChange(startDate, endDate);
    if (history !== null) {
      history.push(
        startDate &&
          startDate !== "select" &&
          (defaultValue === null ||
            (startDate !==
              intervalsDate.find((interv) => interv.icon === defaultValue)
                .value &&
              (endDate === null ||
                Math.floor(endDate / 1000) < Math.floor(Date.now() / 1000))))
          ? history.location.pathname +
              modifyAddress(
                history.location.search,
                ["startDate", "endDate", "timeInterval"],
                intervalsDate.find(
                  (interval) => interval.value === startDate
                ) &&
                  (endDate === null ||
                    Math.floor(endDate / 1000) ===
                      Math.floor(Date.now() / 1000))
                  ? `timeInterval=${
                      intervalsDate.find(
                        (interval) => interval.value === startDate
                      ).icon
                    }`
                  : `startDate=${
                      endDate - Number(startDate) * 1000
                    }&endDate=${endDate}`
              )
          : history.location.pathname +
              modifyAddress(history.location.search, [
                "startDate",
                "endDate",
                "timeInterval",
              ])
      );
    }
  };

  const changeDatepickerTime = () => {
    if (document.getElementById("datepicker_input") !== null) {
      document.getElementById("datepicker_input").blur();
    }
    if (
      time.length !== 0 &&
      !isNaN(moment(time.split("-")[0]).unix()) &&
      !isNaN(moment(time.split("-")[1]).unix()) &&
      moment(
        isNaN(moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix())
          ? new Date(
              timeInterval !== null ? Date.now() - timeInterval : value
            ).getFullYear() +
              " " +
              time.split("-")[0]
          : time.split("-")[0],
        "YYYY MMM D, h:mm a"
      ).unix() <
        moment(
          isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
            ? new Date(
                timeInterval !== null ? Date.now() - timeInterval : value
              ).getFullYear() +
                " " +
                time.split("-")[1]
            : time.split("-")[1],
          "YYYY MMM D, h:mm a"
        ).unix() &&
      moment(
        isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
          ? new Date(
              timeInterval !== null ? Date.now() - timeInterval : value
            ).getFullYear() +
              " " +
              time.split("-")[1]
          : time.split("-")[1],
        "YYYY MMM D, h:mm a"
      ).unix() <
        Date.now() / 1000 &&
      !isNaN(
        Number(
          isNaN(moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix())
            ? new Date(timeInterval !== null ? Date.now() : end).getFullYear()
            : time.split("-")[0].trim().slice(0, 4)
        )
      ) &&
      Number(
        isNaN(moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix())
          ? new Date(timeInterval !== null ? Date.now() : end).getFullYear()
          : time.split("-")[0].trim().slice(0, 4)
      ) >= 2019 &&
      !isNaN(
        Number(
          isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
            ? new Date(
                timeInterval !== null ? Date.now() - timeInterval : value
              ).getFullYear()
            : time.split("-")[1].trim().slice(0, 4)
        )
      ) &&
      Number(
        isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
          ? new Date(
              timeInterval !== null ? Date.now() - timeInterval : value
            ).getFullYear()
          : time.split("-")[1].trim().slice(0, 4)
      ) >= 2019
    ) {
      onChangeAction(
        (time.split("-")[1] && time.split("-")[1] !== "select"
          ? moment(
              isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
                ? new Date(
                    timeInterval !== null ? Date.now() - timeInterval : value
                  ).getFullYear() +
                    " " +
                    time.split("-")[1]
                : time.split("-")[1],
              "YYYY MMM D, h:mm a"
            ).unix()
          : Date.now() / 1000) -
          moment(
            isNaN(moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix())
              ? new Date(
                  timeInterval !== null ? Date.now() - timeInterval : value
                ).getFullYear() +
                  " " +
                  time.split("-")[0]
              : time.split("-")[0],
            "YYYY MMM D, h:mm a"
          ).unix(),
        moment(
          isNaN(moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix())
            ? new Date(
                timeInterval !== null ? Date.now() - timeInterval : value
              ).getFullYear() +
                " " +
                time.split("-")[1]
            : time.split("-")[1],
          "YYYY MMM D, h:mm a"
        ).unix() * 1000
      );
      setTime([]);
      setSelectOpened(false);
    } else {
      setTime([]);
      setSelectOpened(false);
    }
  };

  function checkClick(e) {
    if (
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementsByClassName("datepicker--container")[0]
      )
    ) {
      setSelectOpened(false);
      setOpen(false);
      return document.removeEventListener("click", checkClick);
    } else if (
      (e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementsByClassName("datepicker--container")[0]
      ) &&
      !(e.path || (e.composedPath && e.composedPath())).includes(
        document.getElementsByClassName("datepicker--calendar--identifier")[0]
      ) &&
      isOpen
    ) {
      setOpen(false);
      return document.removeEventListener("click", checkClick);
    }
  }

  const handleChange = (value) => {
    value !== "select"
      ? onChangeAction(value, null)
      : onChangeAction(value, value);
    setOpen(false);
  };

  return (
    <div className="datepicker--container">
      <div className="col-12">
        <div className="form-group">
          <div
            className={`datepicker--span ${
              selectOpened ? "datepicker--span-visible" : ""
            }`}
          >
            {((value !== null && value !== "select") ||
              timeInterval !== null) && (
              <div className="datepicker--icon">
                {(!isNaN(end) && !isNaN(value)) || timeInterval !== null ? (
                  intervalsDate
                    .map((date) => date.value)
                    .includes(Number(timeInterval)) ||
                  intervalsDate
                    .map((date) => date.value)
                    .includes(
                      Math.floor(end / 1000) - Math.floor(value / 1000)
                    ) ? (
                    intervalsDate.find(
                      (interval) =>
                        interval.value === Number(timeInterval) ||
                        interval.value ===
                          Math.floor(end / 1000) - Math.floor(value / 1000)
                    ).icon
                  ) : (
                    <FontAwesomeIcon icon={faCalendar} />
                  )
                ) : (
                  ""
                )}
              </div>
            )}
            {(value !== null && value !== "select") || timeInterval !== null ? (
              <input
                id="datepicker_input"
                className={
                  time.length !== 0 &&
                  (isNaN(moment(time.split("-")[0]).unix()) ||
                    isNaN(moment(time.split("-")[1]).unix()) ||
                    moment(
                      isNaN(
                        moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix()
                      )
                        ? new Date(
                            timeInterval !== null
                              ? Date.now() - timeInterval
                              : value
                          ).getFullYear() +
                            " " +
                            time.split("-")[0]
                        : time.split("-")[0],
                      "YYYY MMM D, h:mm a"
                    ).unix() >
                      moment(
                        isNaN(
                          moment(
                            time.split("-")[1],
                            "YYYY MMM D, h:mm a"
                          ).unix()
                        )
                          ? new Date(
                              timeInterval !== null
                                ? Date.now() - timeInterval
                                : value
                            ).getFullYear() +
                              " " +
                              time.split("-")[1]
                          : time.split("-")[1],
                        "YYYY MMM D, h:mm a"
                      ).unix() ||
                    moment(
                      isNaN(
                        moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix()
                      )
                        ? new Date(
                            timeInterval !== null
                              ? Date.now() - timeInterval
                              : value
                          ).getFullYear() +
                            " " +
                            time.split("-")[1]
                        : time.split("-")[1],
                      "YYYY MMM D, h:mm a"
                    ).unix() >
                      Date.now() / 1000 ||
                    isNaN(
                      Number(
                        isNaN(
                          moment(
                            time.split("-")[0],
                            "YYYY MMM D, h:mm a"
                          ).unix()
                        )
                          ? new Date(
                              timeInterval !== null ? Date.now() : end
                            ).getFullYear()
                          : time.split("-")[0].trim().slice(0, 4)
                      )
                    ) ||
                    Number(
                      isNaN(
                        moment(time.split("-")[0], "YYYY MMM D, h:mm a").unix()
                      )
                        ? new Date(
                            timeInterval !== null ? Date.now() : end
                          ).getFullYear()
                        : time.split("-")[0].trim().slice(0, 4)
                    ) < 2019 ||
                    isNaN(
                      Number(
                        isNaN(
                          moment(
                            time.split("-")[1],
                            "YYYY MMM D, h:mm a"
                          ).unix()
                        )
                          ? new Date(
                              timeInterval !== null
                                ? Date.now() - timeInterval
                                : value
                            ).getFullYear()
                          : time.split("-")[1].trim().slice(0, 4)
                      )
                    ) ||
                    Number(
                      isNaN(
                        moment(time.split("-")[1], "YYYY MMM D, h:mm a").unix()
                      )
                        ? new Date(
                            timeInterval !== null
                              ? Date.now() - timeInterval
                              : value
                          ).getFullYear()
                        : time.split("-")[1].trim().slice(0, 4)
                    ) < 2019) &&
                  selectOpened
                    ? "invalid"
                    : ""
                }
                onKeyPress={(e) => {
                  if (e.keyCode === 13 || e.which === 13 || e.charCode === 13) {
                    e.preventDefault();
                    changeDatepickerTime();
                  }
                }}
                onChange={(e) => {
                  setTime(e.target.value);
                }}
                value={
                  time.length !== 0 && selectOpened
                    ? time
                    : timeInterval !== null
                    ? `${moment(Date.now() - timeInterval * 1000).format(
                        new Date(
                          Date.now() - timeInterval * 1000
                        ).getFullYear() === new Date().getFullYear()
                          ? "MMM D, h:mm a"
                          : "YYYY MMM D, h:mm a"
                      )} - ${moment(Date.now()).format(
                        new Date(
                          Date.now() - timeInterval * 1000
                        ).getFullYear() === new Date().getFullYear()
                          ? "MMM D, h:mm a"
                          : "YYYY MMM D, h:mm a"
                      )}`
                    : `${moment(Math.floor(value / 1000) * 1000).format(
                        new Date(
                          Math.floor(value / 1000) * 1000
                        ).getFullYear() === new Date().getFullYear()
                          ? "MMM D, h:mm a"
                          : "YYYY MMM D, h:mm a"
                      )} - ${moment(Math.floor(end / 1000) * 1000).format(
                        new Date(
                          Math.floor(end / 1000) * 1000
                        ).getFullYear() === new Date().getFullYear() &&
                          new Date(
                            Math.floor(value / 1000) * 1000
                          ).getFullYear() === new Date().getFullYear()
                          ? "MMM D, h:mm a"
                          : "YYYY MMM D, h:mm a"
                      )}`
                }
              />
            ) : (
              <span className="span--placeholder">
                {intervalsDate[0].label}
              </span>
            )}
          </div>
          <ul
            className={`form-control custom-select custom--datepicker--select ${
              value === "select" && "date--placeholder"
            }`}
            onClick={(e) => {
              setSelectOpened((prev) => !prev);
            }}
          >
            {selectOpened && (
              <>
                {(removeSelect
                  ? intervalsDate.slice(1, selectMax || intervalsDate.length)
                  : intervalsDate.slice(0, selectMax || intervalsDate.length)
                ).map((option) => (
                  <li
                    key={option.value}
                    value={option.icon}
                    className="option--icon"
                    onClick={() =>
                      option.value !== "Calendar"
                        ? handleChange(option.value)
                        : setOpen(true)
                    }
                  >
                    <div className="datepicker--option--container">
                      {option.icon && (
                        <div className="li--icon">
                          <span>{option.icon}</span>
                        </div>
                      )}
                      {option.label}
                    </div>
                  </li>
                ))}
                <li
                  key="Calendar"
                  value="Calendar"
                  className="option--icon"
                  onClick={() => setOpen(true)}
                >
                  <div className="datepicker--option--container">
                    <div className="li--icon">
                      <span>
                        <FontAwesomeIcon icon={faCalendar} />
                      </span>
                    </div>
                    Select from calendar
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      {isOpen && (
        <DateRange
          className="datepicker--calendar--identifier"
          editableDateInputs={true}
          showDateDisplay={false}
          onChange={(item) => setCalendarState([item.selection])}
          maxDate={new Date()}
          minDate={new Date(2019, 0, 1)}
          moveRangeOnFirstSelection={false}
          retainEndDateOnFirstSelection={true}
          ranges={[
            {
              startDate:
                calendarState[0].startDate.getTime() !== new Date().getTime() ||
                isNaN(value)
                  ? calendarState[0].startDate
                  : new Date(value),
              endDate: calendarState[0].endDate,
              key: "selection",
            },
          ]}
        />
      )}
      <NotificationContainer enterTimeout={400} leaveTimeout={400} />
    </div>
  );
};
