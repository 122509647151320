import request from '../utils/request';

export function getSessions(params) {
  return request({
    url: '/auth/sessions',
    method: 'get',
    data: params,
  });
}

export function deleteSession(uuid) {
  return request({
    url: `/auth/sessions/${uuid}`,
    method: 'delete',
  });
}
