import { getCatering, getTransactionDetails } from "../../api/transactions";

export const CHANGE_CATERING_VALUE = "CHANGE_CATERING_VALUE";
export const CHANGE_CATERING_DATE = "CHANGE_CATERING_DATE";
export const RESET_CATERING_STORE = "RESET_CATERING_STORE";

export const GET_CATERING = "GET_CATERING";
export const GET_CATERING_SUCCESS = "GET_CATERING_SUCCESS";
export const GET_CATERING_ERROR = "GET_CATERING_ERROR";

export const GET_CATERING_DETAILS = "GET_CATERING_DETAILS";
export const GET_CATERING_DETAILS_SUCCESS = "GET_CATERING_DETAILS_SUCCESS";
export const GET_CATERING_DETAILS_ERROR = "GET_CATERING_DETAILS_ERROR";

export const GET_CATERING_RESTAURANTS = "GET_CATERING_RESTAURANTS";
export const GET_CATERING_RESTAURANTS_SUCCESS =
  "GET_CATERING_RESTAURANTS_SUCCESS";
export const GET_CATERING_RESTAURANTS_ERROR = "GET_CATERING_RESTAURANTS_ERROR";

export const GET_CATERING_SMALL_ANALYTIC = "GET_CATERING_SMALL_ANALYTIC";
export const GET_CATERING_SMALL_ANALYTIC_SUCCESS =
  "GET_CATERING_SMALL_ANALYTIC_SUCCESS";
export const GET_CATERING_SMALL_ANALYTIC_ERROR =
  "GET_CATERING_SMALL_ANALYTIC_ERROR";

export const getCateringAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({
      type: GET_CATERING,
      payload: params.cursorDirection === "FIRST",
    });
    try {
      const response = await getCatering(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch({ type: GET_CATERING_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CATERING_ERROR });
    }
  };
};

export const getCateringDetailsAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_CATERING_DETAILS });
    try {
      const response = await getTransactionDetails(uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_CATERING_DETAILS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CATERING_DETAILS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_CATERING_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_CATERING_DATE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_CATERING_STORE,
});
