import {
  getPins,
  addPinsMaterial,
  updatePinsMaterial,
  deletePinsMaterial,
} from "../../api/pins";

export const CHANGE_PINS_VALUE = "CHANGE_PINS_VALUE";

export const RESET_PINS_STORE = "RESET_PINS_STORE";

export const GET_PINS = "GET_PINS";
export const GET_PINS_SUCCESS = "GET_PINS_SUCCESS";
export const GET_PINS_ERROR = "GET_PINS_ERROR";

export const getPinsAction = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_PINS });
    try {
      const response = await getPins(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_PINS_SUCCESS, params: params, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_PINS_ERROR });
    }
  };
};

export const addPinsMaterialAction = (params, actions = {}) => {
  return async (dispatch) => {
    try {
      const response = await addPinsMaterial(params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const updatePinsMaterialAction = (
  materialUuid,
  params,
  actions = {}
) => {
  return async (dispatch) => {
    try {
      const response = await updatePinsMaterial(materialUuid, params);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const deletePinsMaterialAction = (materialUuid, actions = {}) => {
  return async (dispatch) => {
    try {
      const response = await deletePinsMaterial(materialUuid);
      const { success, results } = response;
      if (success) {
        actions.onSuccess && actions.onSuccess();
      }
    } catch (error) {
      console.log(error);
      actions.onError && actions.onError(error.message);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_PINS_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_PINS_STORE,
});
