import {
  getDestinationCodes,
  getRestaurants,
  getTransactionAnalytics,
  getTransactionDetails,
  getTransactions,
} from "../../api/transactions";

export const CHANGE_TRANSACTIONS_VALUE = "CHANGE_TRANSACTIONS_VALUE";
export const CHANGE_TRANSACTIONS_DATE = "CHANGE_TRANSACTIONS_DATE";
export const RESET_TRANSACTIONS_STORE = "RESET_TRANSACTIONS_STORE";

export const GET_TRANSACTIONS = "GET_TRANSACTIONS";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_ERROR = "GET_TRANSACTIONS_ERROR";

export const GET_TRANSACTIONS_RESTAURANTS = "GET_TRANSACTIONS_RESTAURANTS";
export const GET_TRANSACTIONS_RESTAURANTS_SUCCESS =
  "GET_TRANSACTIONS_RESTAURANTS_SUCCESS";
export const GET_TRANSACTIONS_RESTAURANTS_ERROR =
  "GET_TRANSACTIONS_RESTAURANTS_ERROR";

export const GET_DESTINATION_CODES = "GET_DESTINATION_CODES";
export const GET_DESTINATION_CODES_SUCCESS = "GET_DESTINATION_CODES_SUCCESS";
export const GET_DESTINATION_CODES_ERROR = "GET_DESTINATION_CODES_ERROR";

export const GET_TRANSACTION_DETAILS = "GET_TRANSACTION_DETAILS";
export const GET_TRANSACTION_DETAILS_SUCCESS =
  "GET_TRANSACTION_DETAILS_SUCCESS";
export const GET_TRANSACTION_DETAILS_ERROR = "GET_TRANSACTION_DETAILS_ERROR";

export const GET_TRANSACTIONS_SMALL_ANALYTIC =
  "GET_TRANSACTIONS_SMALL_ANALYTIC";
export const GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS =
  "GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS";
export const GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR =
  "GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR";

export const getTransactionsAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS,
      payload: params.cursorDirection === "FIRST",
    });
    try {
      const response = await getTransactions(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch({ type: GET_TRANSACTIONS_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTIONS_ERROR });
    }
  };
};

export const getRestaurantsAction = (setData) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS_RESTAURANTS,
    });
    try {
      const response = await getRestaurants();
      const { success, results } = response;
      if (success) {
        setData(results.map((res) => ({ label: res, value: res })));
        dispatch({
          type: GET_TRANSACTIONS_RESTAURANTS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTIONS_RESTAURANTS_ERROR });
    }
  };
};

export const getTransactionsAnalyticAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({
      type: GET_TRANSACTIONS_SMALL_ANALYTIC,
    });
    try {
      const response = await getTransactionAnalytics(params);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_TRANSACTIONS_SMALL_ANALYTIC_SUCCESS,
          payload: { results, params },
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTIONS_SMALL_ANALYTIC_ERROR });
    }
  };
};

export const getTransactionDetailsAction = (uuid, params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_TRANSACTION_DETAILS });
    try {
      const response = await getTransactionDetails(uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({
          type: GET_TRANSACTION_DETAILS_SUCCESS,
          payload: results,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTION_DETAILS_ERROR });
    }
  };
};

export const getDestinationCodesAction = (setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_DESTINATION_CODES });
    try {
      const response = await getDestinationCodes();
      const { success, results } = response;
      if (success) {
        setData(results.map((result) => ({ label: result, value: result })));
        dispatch({
          type: GET_DESTINATION_CODES_SUCCESS,
          payload: results.map((result) => ({ label: result, value: result })),
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_DESTINATION_CODES_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TRANSACTIONS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_TRANSACTIONS_DATE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TRANSACTIONS_STORE,
});
