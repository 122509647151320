import * as types from "../actions/recDoneAction";

const initialState = {
  uuid: "",
  locationNum: "select",
  startDate: null,
  endDate: null,
  timeInterval: null,
  isFileLoading: false,
  loading: false,
  totalResults: 0,
  size: 20,
  page: 0,
};

const recDoneReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_RECOMMENDATION_DONE_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_RECOMMENDATION_DONE_DATE_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_RECOMMENDATION_DONE:
      return {
        ...state,
        loading: true,
      };
    case types.GET_RECOMMENDATION_DONE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalResults: action.payload.totalElements,
      };
    case types.GET_RECOMMENDATION_DONE_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.message,
      };
    case types.LOAD_REC_DONE_FILE:
      return {
        ...state,
        isFileLoading: true,
      };
    case types.LOAD_REC_DONE_FILE_SUCCESS:
      return {
        ...state,
        isFileLoading: false,
      };
    case types.LOAD_REC_DONE_FILE_ERROR:
      return {
        ...initialState,
        isFileLoading: false,
      };
    case types.RESET_RECOMMENDATION_DONE_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default recDoneReducer;
