import * as types from "../actions/questionAction";

const initialState = {
  loading: false,
  totalResults: 0,
  page: 0,
  size: "20",
  startDate: null,
  endDate: null,
  timeInterval: null,
  location: null,
  modalInf: {
    location: "select",
    message: "",
    type: "select",
    uuid: "",
    locationBlocked: false,
  },
};

const questionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_QUESTIONS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_QUESTIONS_DATE_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_QUESTIONS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.GET_QUESTIONS_ERROR:
      return {
        ...initialState,
        loading: false,
      };
    case types.ADD_QUESTION_SUCCESS:
    case types.ANSWER_QUESTION_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults + 1,
      };
    case types.DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        totalResults: state.totalResults - 1,
      };
    case types.RESET_QUESTIONS_STORE:
      return {
        ...state,
        ...initialState,
      };
    case types.CHANGE_MODAL_INF:
      return {
        ...state,
        modalInf: {
          ...state.modalInf,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export default questionsReducer;
