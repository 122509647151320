import request from "../utils/request";

export function changePassword(params) {
  return request({
    url: "/users/password",
    method: "put",
    data: params,
  });
}

export function getMe() {
  return request({
    url: `/auth/check`,
    method: "get",
  });
}

export function forgotPassword(params) {
  return request({
    url: "/users/password/forgot",
    method: "post",
    data: params,
  });
}
