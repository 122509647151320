import "./NavTabs.scss";

const NavTab = ({ i, span, active, onClick }) => {
  return (
    <li className={active ? "nav-item active" : "nav-item"} onClick={onClick}>
      <a
        aria-selected="true"
        className={active ? "nav-link active" : "nav-link"}
        data-toggle="tab"
        role="tab"
      >
        {i ? <i className={i}></i> : ""}
        <span>{span}</span>
      </a>
    </li>
  );
};

export default NavTab;
