import request from "../utils/request";

export function getProduction(params) {
  return request({
    url: "/recommendation/list",
    method: "get",
    data: params,
  });
}

export function submitProduction(uuid) {
  return request({
    url: `recommendation/${uuid}`,
    method: "put",
  })
}