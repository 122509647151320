import request from "../utils/request";

export function startProd(params) {
  return request({
    url: "/order/start",
    method: "post",
    data: params,
  });
}

export function stopProd(params) {
  return request({
    url: "/order/stop",
    method: "post",
    data: params,
  });
}

export function statusOrder() {
  return request({
    url: `order/status`,
    method: "get",
  });
}
