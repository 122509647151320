import { getDlps } from "../../api/dlps";

export const CHANGE_DLPS_VALUE = "CHANGE_DLPS_VALUE";
export const CHANGE_DLPS_DATE_VALUE = "CHANGE_DLPS_DATE_VALUE";

export const RESET_DLPS_STORE = "RESET_DLPS_STORE";

export const GET_DLPS = "GET_DLPS";
export const GET_DLPS_SUCCESS = "GET_DLPS_SUCCESS";
export const GET_DLPS_ERROR = "GET_DLPS_ERROR";

export const getDlpsAction = (uuid, params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_DLPS });
    try {
      const response = await getDlps(uuid, params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_DLPS_SUCCESS, params: params, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_DLPS_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_DLPS_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeDate = (value) => ({
  type: CHANGE_DLPS_DATE_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_DLPS_STORE,
});
