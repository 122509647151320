import request from "../utils/request";

export function getHistory(params) {
  return request({
    url: "/order/list",
    method: "get",
    data: params,
  });
}

export function getAnalytics(params) {
  return request({
    url: "/analytic",
    method: "get",
    data: params,
  });
}

export function getCsvExport(params) {
  return request({
    url: "/order/csv",
    method: "get",
    data: params,
    responseType: "arraybuffer",
  });
}
