import * as types from "../actions/settingsAction";

const initialState = {
  startTime: "00:00",
  testTime: "00:00",
  pickerTime: "00:00",
  status: "INACTIVE",
  loading: false,
  restaurantUuid: "select",
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SETTINGS_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.START_PROD_PROCESS:
      return {
        ...state,
        loading: true,
      };
    case types.START_PROD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case types.START_PROD_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.STOP_PROD_PROCESS:
      return {
        ...state,
        loading: true,
      };
    case types.STOP_PROD_SUCCESS:
      return {
        ...initialState,
        loading: false,
      };
    case types.STOP_PROD_ERROR:
      return {
        ...initialState,
        loading: false,
        errorMessage: action.message,
      };
    case types.GET_RESTAURANTS:
      return {
        ...state,
        loading: true,
      };

    case types.GET_RESTAURANTS_RESULTS:
      return {
        ...state,
        loading: false,
      };

    case types.GET_RESTAURANTS_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_SETTINGS_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default settingsReducer;
