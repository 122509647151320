import { useEffect } from "react";
import { modifyAddress } from "../../utils";
import "./Select.scss";

export const Select = ({
  className,
  name,
  label = false,
  history = null,
  options,
  value,
  onChange,
  disabled = false,
  defaultValue = null,
  changedSearch,
  setChangedSearch,
  removeAlso,
  dataToggle = false,
  dataPlacement = "top",
  tooltipTitle = false,
  title,
  removeMargin = false,
  withoutSelect = false,
}) => {
  useEffect(() => {
    if (
      name &&
      history &&
      history.location &&
      history.location.search.length !== 0 &&
      history.location.search.includes(name) &&
      options.length > 1
    ) {
      let search = history.location.search
        .slice(1)
        .replaceAll("%20", " ")
        .split("&")
        .find((value) => value.includes(name))
        ?.slice(name.length + 1);
      if (
        search.length !== 0 &&
        options
          .map((option) => option.value.toString())
          .includes(search.toString())
      ) {
        onChange(name, search);
      } else {
        history.push(
          history.location.pathname +
            modifyAddress(history.location.search, [name])
        );
      }
    }
  }, [options]);

  useEffect(() => {
    if (changedSearch) {
      let search = history.location.search
        .slice(1)
        .replaceAll("%20", " ")
        .split("&")
        .find((value) => value.includes(name));
      if (!history.location.search.includes(name) && value !== "select") {
        onChange(name, defaultValue ? defaultValue : "select");
      } else if (search && search.slice(name.length + 1) !== value) {
        onChange(name, search.slice(name.length + 1));
      }
      setChangedSearch(false);
    } else if (
      value === null &&
      history &&
      !history.location.search
        .slice(1)
        .replaceAll("%20", " ")
        .split("&")
        .find((value) => value.includes(name))
    ) {
      onChange(name, defaultValue ? defaultValue : "select");
    }
  }, [changedSearch]);

  const handleChange = (e) => {
    if (name) {
      onChange(name, e.target.value);
      if (history !== null) {
        e.target.value !== "select"
          ? history.push(
              history.location.pathname +
                modifyAddress(
                  history.location.search,
                  removeAlso ? [name, removeAlso] : [name],
                  `${name}=${e.target.value}`
                )
            )
          : history.push(
              history.location.pathname +
                modifyAddress(
                  history.location.search,
                  removeAlso ? [name, removeAlso] : [name]
                )
            );
      }
    } else onChange(e.target.value);
  };

  return (
    <div className={className}>
      <div
        className={`${!removeMargin ? "form-group" : ""} form-group--select`}
      >
        {label ? <label>{label}</label> : ""}
        <select
          className={`form-control custom-select ${
            value === "select" ? "placeholder" : ""
          } page--select`}
          value={value}
          disabled={disabled}
          data-toggle={dataToggle ? dataToggle : false}
          data-placement={dataPlacement}
          title={tooltipTitle ? tooltipTitle : false}
          onChange={handleChange}
        >
          {!withoutSelect ? (
            <option key="select" value="select">
              Select {title}
            </option>
          ) : (
            ""
          )}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};
