import {
  getDoneRecommendations,
  getCsvExport,
} from "../../api/doneRecommendation";
import { downloadFile } from "../../utils";

export const CHANGE_RECOMMENDATION_DONE_VALUE =
  "CHANGE_RECOMMENDATION_DONE_VALUE";
export const CHANGE_RECOMMENDATION_DONE_DATE_VALUE =
  "CHANGE_RECOMMENDATION_DONE_DATE_VALUE";
export const RESET_RECOMMENDATION_DONE_STORE =
  "RESET_RECOMMENDATION_DONE_STORE";

export const GET_RECOMMENDATION_DONE = "GET_RECOMMENDATION_DONE";
export const GET_RECOMMENDATION_DONE_SUCCESS =
  "GET_RECOMMENDATION_DONE_SUCCESS";
export const GET_RECOMMENDATION_DONE_ERROR = "GET_RECOMMENDATION_DONE_ERROR";

export const LOAD_REC_DONE_FILE = "LOAD_REC_DONE_FILE";
export const LOAD_REC_DONE_FILE_SUCCESS = "LOAD_REC_DONE_FILE_SUCCESS";
export const LOAD_REC_DONE_FILE_ERROR = "LOAD_REC_DONE_FILE_ERROR";

export const getRecDoneAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_RECOMMENDATION_DONE });
    try {
      const response = await getDoneRecommendations(params);
      const { success, results } = response;
      if (success) {
        setData([...results.content]);
        dispatch({ type: GET_RECOMMENDATION_DONE_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_RECOMMENDATION_DONE_ERROR });
    }
  };
};

export const downloadRecDoneFile = (params) => {
  return async (dispatch) => {
    dispatch({ type: LOAD_REC_DONE_FILE });
    try {
      const response = await getCsvExport(params);
      const type = "text/csv";
      const { success, results } = response;
      if (success) {
        downloadFile(results, type);
        dispatch({ type: LOAD_REC_DONE_FILE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: LOAD_REC_DONE_FILE_ERROR });
    }
  };
};

export const changeDate = (value) => ({
  type: CHANGE_RECOMMENDATION_DONE_DATE_VALUE,
  payload: value,
});

export const changeValue = (key, value) => ({
  type: CHANGE_RECOMMENDATION_DONE_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_RECOMMENDATION_DONE_STORE,
});
