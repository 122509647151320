import { getAnalytics } from "../../api/history";

export const CHANGE_ORDERS_ANALYTIC_VALUE = "CHANGE_ORDERS_ANALYTIC_VALUE";
export const CHANGE_ORDERS_ANALYTIC_TIME_VALUE =
  "CHANGE_ORDERS_ANALYTIC_TIME_VALUE";

export const RESET_ORDERS_ANALYTIC_STORE = "RESET_ORDERS_ANALYTIC_STORE";

export const GET_ORDERS_ANALYTIC = "GET_ORDERS_ANALYTIC";
export const GET_ORDERS_ANALYTIC_SUCCESS = "GET_ORDERS_ANALYTIC_SUCCESS";
export const GET_ORDERS_ANALYTIC_ERROR = "GET_ORDERS_ANALYTIC_ERROR";

export const getOrdersAnalyticAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_ORDERS_ANALYTIC });
    try {
      const response = await getAnalytics(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_ORDERS_ANALYTIC_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_ORDERS_ANALYTIC_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_ORDERS_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeTimeValue = (value) => ({
  type: CHANGE_ORDERS_ANALYTIC_TIME_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_ORDERS_ANALYTIC_STORE,
});
