import React, { useEffect, useRef, useState } from "react";
import Chart from "react-google-charts";
import {
  googleColors,
  intervals,
  pieChartThousands,
  timeIntervals,
} from "../../constants/analytics.constants";

import "./Analytics.scss";
import { NavTabs } from "../NavTabs/NavTabs";
import { Select } from "../Select/Select";
import { HeaderCell } from "../NewTable/HeaderCell";
import moment from "moment";
import { convertThousands } from "../../utils";

export const Analytics = ({
  isLoading,
  data,
  toolbarElements,
  sidebar,
  activeTab,
  interval,
  dates,
  changeValue,
}) => {
  const [chartType, setChartType] = useState("lines");
  const [intervalState, setIntervalState] = useState(
    intervals.slice(timeIntervals[0].start, timeIntervals[0].end)
  );
  const [resize, setResize] = useState("right");

  useEffect(() => {
    setTimeout(function () {
      setResize(sidebar ? "right" : "left");
    }, 500);
  }, [sidebar]);

  useEffect(() => {
    if (
      (dates[0] &&
        dates[0] !== "select" &&
        dates[1] &&
        dates[1] !== "select") ||
      dates[2] !== null
    ) {
      let searchInterval = timeIntervals.find(
        (time) =>
          time.value ===
          parseInt(dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000)
      );
      if (searchInterval) {
        setIntervalState(
          intervals.slice(searchInterval.start, searchInterval.end)
        );
        changeValue("interval", intervals[searchInterval.auto].value);
      } else {
        let searchCalendar = timeIntervals
          .slice(0, timeIntervals.length - 1)
          .find(
            (time, index) =>
              time.value >=
                parseInt(
                  dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000
                ) &&
              parseInt(
                dates[2] !== null ? dates[2] : (dates[1] - dates[0]) / 1000
              ) <= timeIntervals[index + 1].value
          );
        if (searchCalendar) {
          setIntervalState(
            intervals.slice(searchCalendar.start, searchCalendar.end)
          );
          changeValue("interval", intervals[searchCalendar.auto].value);
        } else {
          setIntervalState(
            intervals.slice(
              timeIntervals[timeIntervals.length - 1].start,
              timeIntervals[timeIntervals.length - 1].end
            )
          );
          changeValue(
            "interval",
            intervals[timeIntervals[timeIntervals.length - 1].auto].value
          );
        }
      }
    } else {
      setIntervalState(
        intervals.slice(timeIntervals[0].start, timeIntervals[0].end)
      );
      changeValue("interval", intervals[timeIntervals[0].auto].value);
    }
  }, [dates[0], dates[2], interval]);

  return (
    resize && (
      <div className="chart-graphics--area">
        <div className="table__toolbar" id="analytics__toolbar">
          {toolbarElements}
        </div>
        <NavTabs
          className="nav-tabs mb-3"
          name="activeTab"
          navTabs={[
            { label: "Timeseries", value: "graphic" },
            { label: "Bar Chart", value: "bar" },
            { label: "Pie Chart", value: "pie" },
          ]}
          activeTab={activeTab || "graphic"}
          onChange={changeValue}
        />
        {isLoading ? (
          <div className="spinner-container">
            <i className="fa fa-spinner fa-spin fa-2x"></i>
          </div>
        ) : activeTab === "graphic" ? (
          <div className="chart--container">
            <div className="table__toolbar--right">
              <Select
                className="col-10 col-md-3 col-lg-2"
                options={[
                  { label: "Lines", value: "lines" },
                  { label: "Stacked Area", value: "areas" },
                  { label: "Bars", value: "bars" },
                ]}
                withoutMargin={true}
                withoutSelect={true}
                value={chartType}
                onChange={setChartType}
              />
              <Select
                name="interval"
                className="col-1 select--short"
                options={intervalState}
                withoutMargin={true}
                withoutSelect={true}
                value={interval}
                onChange={changeValue}
              />
            </div>
            {data !== null && (
              <Chart
                chartType={
                  chartType === "lines"
                    ? "LineChart"
                    : chartType === "areas"
                    ? "AreaChart"
                    : "ColumnChart"
                }
                data={[
                  [
                    "Timestamp",
                    { type: "string", role: "tooltip", p: { html: true } },
                    ...data.restaurants,
                  ],
                  ...data.graphicValues.map((value) => [
                    value[0],
                    `<div style="width: max-content; flex-wrap: nowrap; display: flex; flex-direction: column; padding: 5px 5px 5px 5px; font-size: 14px; line-height: 1.2;"><b>${moment(
                      value[0]
                    ).format("MMM D, YYYY, HH:mm:ss")}</b>${`${value
                      .slice(1)
                      .map((content, index) => [
                        data.restaurants[index],
                        content,
                      ])
                      .sort((x, y) => {
                        return y[1] - x[1];
                      })
                      .map(
                        (content, index) =>
                          `<div>${content[0]}: <b>${content[1]}</b></div>`
                      )}`.replaceAll(",", "")}</div>`,
                    ...value.slice(1),
                  ]),
                ]}
                options={{
                  animation: { startup: true, duration: 1000, easing: "out" },
                  focusTarget: "category",
                  tooltip: { isHtml: true },
                  legend: { position: "bottom" },
                  isStacked: true,
                  bar: { groupWidth: "75%" },
                  width: "100%",
                  height: "90%",
                  hAxis: {
                    minValue: new Date(
                      Math.floor(
                        dates[2] !== null
                          ? Date.now() - dates[2] * 1000
                          : dates[0]
                      )
                    ),
                    gridlines: {
                      units: {
                        minutes: {
                          format: ["HH:mm"],
                        },
                        hours: {
                          format: ["HH:mm"],
                        },
                        days: {
                          format: ["MM/dd"],
                        },
                        months: {
                          format: ["MM/YYYY"],
                        },
                        years: {
                          format: ["YYYY"],
                        },
                      },
                    },
                    minorGridlines: {
                      units: {
                        minutes: {
                          format: ["HH:mm"],
                        },
                        hours: {
                          format: ["HH:mm"],
                        },
                        days: {
                          format: ["MM/dd"],
                        },
                        months: {
                          format: ["MM/YYYY"],
                        },
                        years: {
                          format: ["YYYY"],
                        },
                      },
                    },
                  },
                  vAxis: {
                    gridlines: {
                      count: 4,
                    },
                    viewWindow: {
                      min: 0,
                    },
                    minValue: 0,
                  },
                  chartArea: { width: "95%", height: "80%", right: 0, top: 50 },
                }}
              />
            )}
          </div>
        ) : activeTab === "bar" ? (
          data !== null && (
            <Chart
              className="bar-chart--analytics"
              chartType="BarChart"
              width="100%"
              height="90%"
              data={[
                [
                  "Restaurant",
                  { type: "string", role: "tooltip", p: { html: true } },
                  "Count",
                  {
                    sourceColumn: 0,
                    role: "annotation",
                    type: "string",
                    calc: "stringify",
                  },
                ],
                ...data.totalValues.map((item) => [
                  item.restaurant,
                  `<div style="width: max-content; flex-wrap: nowrap; display: flex; flex-direction: column; padding: 5px 5px 5px 5px; font-size: 14px; line-height: 1.2;"><b>${
                    item.restaurant
                  }</b>${`<div>Count: <b>${item.value}</b></div>`}</div>`,
                  item.value,
                  item.value,
                ]),
              ]}
              options={{
                animation: { startup: true, duration: 1000, easing: "out" },
                tooltip: { isHtml: true },
                hAxis: {
                  minValue: 0,
                },
                chartArea: { left: 100, width: "90%", height: "80%" },
                legend: { position: "none" },
                annotations: {
                  textStyle: {
                    maxWidth: "100%",
                  },
                  annotationsWidth: 50,
                },
              }}
            />
          )
        ) : (
          activeTab === "pie" &&
          data !== null && (
            <div className="d-flex mt-2 position-relative h-100">
              <div className="piechart--container">
                <Chart
                  className="invisible--chart"
                  chartType="PieChart"
                  data={[
                    ["", "Qty"],
                    [
                      data.totalValues.reduce(function (sum, elem) {
                        return sum + Number(elem.value);
                      }, 0) !== 0
                        ? `${convertThousands(
                            data.totalValues.reduce(function (sum, elem) {
                              return sum + Number(elem.value);
                            }, 0) || 0,
                            pieChartThousands
                          )}`
                        : "",
                      100,
                    ],
                  ]}
                  options={{
                    colors: ["#fff"],
                    pieSliceTextStyle: {
                      align: "center",
                      verticalAlign: "middle",
                      fontWeight: 600,
                      color: "black",
                      fontSize: 36,
                    },
                    enableInteractivity: false,
                    pieHole: 0.5,
                    pieSliceText: "label",
                    chartArea: { top: 10 },
                    legend: { position: "none" },
                  }}
                />
                {data.totalValues.length !== 0 && !isLoading && (
                  <div class="circle">
                    <div class="circle__half"></div>
                    <div class="circle__half circle__half--right"></div>
                  </div>
                )}
                <Chart
                  className={`visible--chart${
                    data.totalValues.filter((val) => val.value > 0).length > 1
                      ? ""
                      : " one-pie--chart"
                  }`}
                  chartType="PieChart"
                  data={[
                    [
                      "Restaurant",
                      "Qty",
                      { type: "string", role: "tooltip", p: { html: true } },
                    ],
                    ...data.totalValues.map((item) => [
                      convertThousands(item.value, pieChartThousands),
                      item.value,
                      `<div style="width: max-content; flex-wrap: nowrap; display: flex; flex-direction: column; padding: 5px 5px 5px 5px; font-size: 14px; line-height: 1.2;"><b>${
                        item.restaurant
                      }</b>${`<div>Count: <b>${item.value} (${
                        Math.round(
                          (item.value /
                            data.totalValues.reduce(function (sum, elem) {
                              return sum + Number(elem.value);
                            }, 0) || 0) * 1000
                        ) / 10
                      } %)</b></div>`}</div>`,
                    ]),
                  ]}
                  options={{
                    tooltip: { isHtml: true },
                    pieHole: 0.5,
                    pieSliceText: "label",
                    legend: { position: "none" },
                    backgroundColor: {
                      fill: "rgba(0,0,0,0)",
                    },
                    chartArea: { top: 10 },
                  }}
                />
              </div>
              <div className="w-50">
                <table className={`table__table`}>
                  <thead>
                    <tr>
                      <HeaderCell key={0} label={"Restaurant"} />
                      <HeaderCell key={1} label={"Count"} />
                      <HeaderCell key={2} label={"Share"} />
                    </tr>
                  </thead>
                  <tbody>
                    {data.totalValues.length ? (
                      data.totalValues.map((row, index) => (
                        <tr key={index}>
                          <td key={row.restaurant + index} align="left">
                            <div className="d-flex">
                              <div
                                className="colors--pie-chart"
                                style={{
                                  backgroundColor: googleColors[index],
                                }}
                              ></div>
                              {row.restaurant}
                            </div>
                          </td>
                          <td key={row.value + index} align="left">
                            {row.value}
                          </td>{" "}
                          <td key={row.value + "1" + index} align="left">
                            {Math.round(
                              (row.value /
                                data.totalValues.reduce(function (sum, elem) {
                                  return sum + Number(elem.value);
                                }, 0) || 0) * 1000
                            ) / 10}
                            %
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="20" className="text-muted text-center">
                          {"No records"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )
        )}
      </div>
    )
  );
};
