import * as R from "./routes.constants";

export const pages = [
  {
    link: R.SETTINGS,
    i: "fa fa-cog",
    span: "Settings",
    disabled: false,
  },
  {
    link: R.PACKAGING_SIDE,
    i: "fa fa-cutlery",
    span: "Packaging",
    disabled: false,
  },
  {
    link: R.SERVICE_SIDE,
    i: "fa fa-box-open",
    span: "Service",
    disabled: false,
  },
  {
    link: R.PRODUCTION,
    i: "fas fa-chart-line",
    span: "Production",
    disabled: false,
  },
  {
    link: R.TRANSACTIONS_INFO,
    i: "fas fa-list-alt",
    span: "Transactions",
    disabled: false,
    tooltip: [
      {
        link: R.TRANSACTIONS_INFO,
        i: "",
        span: "Info",
        disabled: false,
      },
      {
        link: R.CATERING,
        i: "",
        span: "Catering",
        disabled: false,
      },
      {
        link: R.TRANSACTIONS_ANALYTIC,
        i: "",
        span: "Analytics",
        disabled: false,
      },
    ],
  },
  {
    link: R.ORDERS_HISTORY,
    i: "fas fa-history",
    span: "Orders History",
    disabled: false,
    tooltip: [
      {
        link: R.ORDERS_HISTORY,
        i: "",
        span: "History",
        disabled: false,
      },
      {
        link: R.ORDERS_ANALYTICS,
        i: "",
        span: "Analytics",
        disabled: false,
      },
    ],
  },
  {
    link: R.REC_HISTORY,
    i: "fas fa-history",
    span: "Recommendation History",
    disabled: false,
  },
  {
    link: R.DLPS,
    i: "fa fa-server",
    span: "DLPS",
    disabled: false,
  },
  {
    link: R.PINS,
    i: "fas fa-map-pin",
    span: "Pins",
    disabled: false,
  },
  {
    link: R.QUESTIONS,
    i: "fa fa-envelope",
    span: "VTM Messages",
    disabled: false,
  },
  {
    link: R.LOGS,
    i: "fa fa-sign-in",
    span: "Logs",
    disabled: false,
  },
];

export const userSidebar = [
  {
    link: R.USER_PROFILE,
    i: "fa fa-user",
    span: "Profile",
    disabled: false,
  },
  {
    link: R.CHANGE_PASSWORD,
    i: "fa fa-lock",
    span: "Change Password",
    disabled: false,
  },
  {
    link: R.AUTHENTIFICATION_LOG,
    i: "fa fa-history",
    span: "Authentication Log",
    disabled: false,
  },
  {
    link: R.ACTIVE_SESSIONS,
    i: "fa fa-desktop",
    span: "Active Sessions",
    disabled: false,
  },
];

export const transactionsTabs = [
  {
    label: "History",
    value: "history",
    i: "",
  },
  {
    label: "Catering",
    value: "catering",
    i: "",
  },
  {
    label: "Analytics",
    value: "analytics",
    i: "",
  },
];

export const ordersTabs = [
  {
    label: "History",
    value: "history",
    i: "",
  },
  {
    label: "Analytics",
    value: "analytics",
    i: "",
  },
];

export const accessNames = [
  { module: "KPS_SETTINGS", pages: [R.SETTINGS] },
  { module: "PACKAGING", pages: [R.PACKAGING_SIDE] },
  { module: "SERVICE", pages: [R.SERVICE_SIDE] },
  { module: "PRODUCTION", pages: [R.PRODUCTION] },
  { module: "VTM_MESSAGES", pages: R.QUESTIONS },
  {
    module: "TRANSACTIONS",
    pages: [R.TRANSACTIONS_INFO, R.CATERING, R.TRANSACTIONS_ANALYTIC],
  },
  { module: "ORDERS", pages: [R.ORDERS_HISTORY, R.ORDERS_ANALYTICS] },
  { module: "RECOMMENDATIONS", pages: [R.REC_HISTORY] },
  { module: "DLPS", pages: [R.DLPS] },
  { module: "PINS", pages: R.PINS },
  { module: "LOGS", pages: R.LOGS },
  { module: "SUPPORT", pages: R.SUPPORT_HISTORY },
];
