export const LOGIN = "/login";
export const PACKAGING_SIDE = "/production-side";
export const SERVICE_SIDE = "/service-side";
export const SETTINGS = "/settings";
export const ORDERS_HISTORY = "/orders/history";
export const ORDERS_ANALYTICS = "/orders/analytics";
export const PRODUCTION = "/production";
export const REC_HISTORY = "/recommendation-history";
export const LOGS = "/logs";
export const QUESTIONS = "/questions";
export const SUPPORT_HISTORY = "/support-history";
export const GET_SUPPORT = "/get-support";
export const TRANSACTIONS_INFO = "/transactions/history";
export const CATERING = "/transactions/catering";
export const CHANGE_PASSWORD = "/user-settings/change-password";
export const USER_PROFILE = "/user-settings/profile";
export const AUTHENTIFICATION_LOG = "/user-settings/authentification-log";
export const ACTIVE_SESSIONS = "/user-settings/active-sessions";
export const TRANSACTIONS_ANALYTIC = "/transactions/analytics";
export const MULTI_FACTOR_AUTH = "/multi-factor-auth";
export const DLPS = "/dlps";
export const PINS = "/pins";
