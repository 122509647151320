import request from "../utils/request";

export function getDoneRecommendations(params) {
  return request({
    url: "/recommendation/list/done",
    method: "get",
    data: params,
  });
} 

export function getCsvExport(params) {
  return request({
    url: "/recommendation/csv",
    method: "get",
    data: params,
  });
} 