import { getAnalytics } from "../../api/history";

export const CHANGE_TRANSACTIONS_ANALYTIC_VALUE =
  "CHANGE_TRANSACTIONS_ANALYTIC_VALUE";
export const CHANGE_TRANSACTIONS_ANALYTIC_TIME_VALUE =
  "CHANGE_TRANSACTIONS_ANALYTIC_TIME_VALUE";
export const RESET_TRANSACTIONS_ANALYTIC_STORE =
  "RESET_TRANSACTIONS_ANALYTIC_STORE";

export const GET_TRANSACTIONS_ANALYTIC = "GET_TRANSACTIONS_ANALYTIC";
export const GET_TRANSACTIONS_ANALYTIC_SUCCESS =
  "GET_TRANSACTIONS_ANALYTIC_SUCCESS";
export const GET_TRANSACTIONS_ANALYTIC_ERROR =
  "GET_TRANSACTIONS_ANALYTIC_ERROR";

export const getTransactionsAnalyticAction = (params, actions = {}) => {
  return async (dispatch) => {
    dispatch({ type: GET_TRANSACTIONS_ANALYTIC });
    try {
      const response = await getAnalytics(params);
      const { success, results } = response;
      if (success) {
        dispatch({ type: GET_TRANSACTIONS_ANALYTIC_SUCCESS, payload: results });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_TRANSACTIONS_ANALYTIC_ERROR });
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_TRANSACTIONS_ANALYTIC_VALUE,
  payload: {
    key,
    value,
  },
});

export const changeTimeValue = (value) => ({
  type: CHANGE_TRANSACTIONS_ANALYTIC_TIME_VALUE,
  payload: value,
});

export const resetStore = () => ({
  type: RESET_TRANSACTIONS_ANALYTIC_STORE,
});
