export const chartColors = [
  "#db6969",
  "#dbac60",
  "#ebdd78",
  "#cde374",
  "#80e079",
  "#6dd6b0",
  "#74d3d6",
  "#72a1db",
  "#b476e3",
  "#e376d3",
  "#ed6fa8",
];
export const eventTypes = [
  { label: "Select Event Type", value: "select" },
  { label: "Chicken", value: "ChickenEvent" },
  { label: "Potato", value: "Event" },
];

export const intervals = [
  { label: "1s", value: 1 },
  { label: "2s", value: 2 },
  { label: "5s", value: 5 },
  { label: "10s", value: 10 },
  { label: "20s", value: 20 },
  { label: "30s", value: 30 },
  { label: "1m", value: 60 },
  { label: "2m", value: 120 },
  { label: "5m", value: 300 },
  { label: "10m", value: 600 },
  { label: "20m", value: 1200 },
  { label: "30m", value: 1800 },
  { label: "1h", value: 3600 },
  { label: "2h", value: 7200 },
  { label: "4h", value: 14400 },
  { label: "8h", value: 28800 },
  { label: "12h", value: 43200 },
  { label: "1d", value: 86400 },
  { label: "2d", value: 172800 },
  { label: "7d", value: 604800 },
  { label: "15d", value: 1296000 },
  { label: "30d", value: 2592000 },
  { label: "90d", value: 7776000 },
  {
    label: "6mo",
    value: Math.floor(
      (Date.now() - new Date().setMonth(new Date().getMonth() - 6)) / 1000
    ),
  },
];

export const timeIntervals = [
  { name: "15m", start: 3, end: 9, value: 900, auto: 3 },
  { name: "1h", start: 4, end: 11, value: 3600, auto: 6 },
  { name: "4h", start: 5, end: 13, value: 14400, auto: 8 },
  { name: "1d", start: 8, end: 16, value: 86400, auto: 10 },
  { name: "2d", start: 8, end: 17, value: 172800, auto: 11 },
  { name: "3d", start: 9, end: 18, value: 259200, auto: 12 },
  { name: "7d", start: 11, end: 18, value: 604800, auto: 13 },
  { name: "15d", start: 12, end: 19, value: 1296000, auto: 14 },
  {
    name: "1mo",
    start: 13,
    end: 20,
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 1)).getMonth() ===
        (new Date().getMonth() - 1 < 0
          ? 12 + (new Date().getMonth() - 1)
          : new Date().getMonth() - 1)
          ? new Date().setMonth(new Date().getMonth() - 1)
          : new Date(new Date().getFullYear(), new Date().getMonth(), 0))) /
        1000
    ),
    auto: 16,
  },
  {
    name: "6mo",
    start: 16,
    end: 22,
    value: Math.floor(
      (Date.now() -
        (new Date(new Date().setMonth(new Date().getMonth() - 6)).getMonth() ===
        (new Date().getMonth() - 6 < 0
          ? 12 + (new Date().getMonth() - 6)
          : new Date().getMonth() - 6)
          ? new Date().setMonth(new Date().getMonth() - 6)
          : new Date(new Date().getFullYear(), new Date().getMonth() - 5, 0))) /
        1000
    ),
    auto: 18,
  },
  {
    name: "1y",
    start: 17,
    end: 23,
    value: Math.floor(
      (Date.now() - new Date().setFullYear(new Date().getFullYear() - 1)) / 1000
    ),
    auto: 19,
  },
];

export const thousands = ["", "K", "M", "B", "T"];

export const googleColors = [
  "#3366cc",
  "#dc3912",
  "#ff9900",
  "#109618",
  "#990099",
  "#0099c6",
  "#dd4477",
  "#66aa00",
  "#b82e2e",
  "#316395",
  "#994499",
  "#22aa99",
  "#aaaa11",
  "#6633cc",
  "#e67300",
  "#8b0707",
  "#651067",
  "#329262",
  "#5574a6",
  "#3b3eac",
  "#b77322",
  "#16d620",
  "#b91383",
  "#f4359e",
  "#9c5935",
  "#a9c413",
  "#2a778d",
  "#668d1c",
  "#bea413",
  "#0c5922",
  "#743411",
];

export const pieChartThousands = ["", "k", "M", "B"];
