import "./Input.scss";

export const Input = ({
  name,
  className,
  value,
  placeholder,
  readOnly,
  type,
  onChange,
}) => {
  const handleChange = (e) => {
    name ? onChange(name, e.target.value) : onChange(e.target.value);
  };
  return (
    <label className={className}>
      <input
        className="form-control form-control-input"
        name={name}
        placeholder={placeholder}
        type={type ? type : "text"}
        value={value === "select" ? "" : value}
        readOnly={readOnly ? true : false}
        autoComplete="off"
        onChange={handleChange}
      />
    </label>
  );
};
