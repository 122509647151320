import React, { Suspense, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";

import { setCurrentPage, setSidebar } from "./redux/actions/appAction";
import {
  changeValue,
  getMeAction,
  resetUser,
  refreshToken,
} from "./redux/actions/loginAction";

import { Preloader } from "./components";
import { accessNames } from "./constants/sidebar.constants";
import routes from "./routes";
import * as authUtils from "./utils/authorization";

function App({
  app,
  login,
  isLoggedIn,
  setSidebar,
  getMeAction,
  changeValue,
  setCurrentPage,
  resetUser,
  refreshToken,
}) {
  const history = useHistory();

  const updateTokensTimer = useRef(null);

  useEffect(() => {
    const handleStorageEvent = (event) => {
      authUtils.checkStorageChanges(event, {
        resetUser,
      });
    };
    window.addEventListener("storage", handleStorageEvent);

    return () => {
      clearInterval(updateTokensTimer.current);
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && login.verified) {
      getMeAction({
        onSuccess: () => {
          checkExpireTokens();
        },
      });
      updateTokensTimer.current = setInterval(
        checkExpireTokens,
        (process.env.REACT_APP_TIME_UNTIL_TOKEN_EXPIRES / 3) * 1000
      );
    }

    return () => {
      clearInterval(updateTokensTimer.current);
    };
  }, [isLoggedIn, login.verified]);

  useEffect(() => {
    if (login.role && login.role !== "ADMIN") {
      let modules = login.accessList.map((item) => item.moduleType);
      changeValue(
        "pages",
        accessNames
          .map((name) => (modules.includes(name.module) ? name.pages : []))
          .flat()
      );
    }
  }, [login.role]);

  const checkExpireTokens = () => {
    const isRefreshing = localStorage.getItem("isRefreshing");
    if (isRefreshing) return;

    const authData = authUtils.getAuthDataFromLocalStorage();
    if (
      authData &&
      authData.refreshToken &&
      authData.accessExpDate &&
      authData.refreshExpDate
    ) {
      const { accessExpDate, refreshExpDate } = authData;
      if (
        new Date(accessExpDate) - new Date() <
          process.env.REACT_APP_TIME_UNTIL_TOKEN_EXPIRES * 1000 &&
        new Date(refreshExpDate) - new Date() > 0
      ) {
        refreshToken();
      }
    }
  };

  return (
    <BrowserRouter history={history}>
      <Suspense fallback={<Preloader />}>
        <Switch>
          {routes.map((route) => (
            <Route
              key={route.id}
              path={route.path}
              exact
              history={history}
              render={(props) => (
                <route.component
                  {...props}
                  isLoggedIn={isLoggedIn}
                  sidebar={app.sidebar}
                  currentPage={app.currentPage}
                  setSidebar={setSidebar}
                  setCurrentPage={setCurrentPage}
                />
              )}
            />
          ))}
          <Redirect from="/" to="/settings" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => ({
  app: state.app,
  login: state.login,
  isLoggedIn: state.login.isLoggedIn,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentPage: (page) => dispatch(setCurrentPage(page)),
    setSidebar: (isOpen) => dispatch(setSidebar(isOpen)),
    getMeAction: (actions) => dispatch(getMeAction(actions)),
    resetUser: () => dispatch(resetUser()),
    refreshToken: () => dispatch(refreshToken()),
    changeValue: (key, value) => dispatch(changeValue(key, value)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
