import Pagination from "react-bootstrap/Pagination";
import "./Pagination.scss";

export const CursorPages = ({
  perPage,
  cursorDirection,
  existNext,
  existPrev,
  onChangePage,
  changePerPage,
}) => {
  const changePages = (value) => {
    (value.indexOf("«") !== -1 || value.indexOf("First") !== -1) &&
      existPrev &&
      onChangePage("FIRST");
    (value.indexOf("‹") !== -1 || value.indexOf("Prev") !== -1) &&
      existPrev &&
      onChangePage("PREVIOUS");
    (value.indexOf("›") !== -1 || value.indexOf("Next") !== -1) &&
      existNext &&
      onChangePage("NEXT");
    (value.indexOf("»") !== -1 || value.indexOf("Last") !== -1) &&
      existNext &&
      onChangePage("LAST");
    !isNaN(parseInt(value)) && onChangePage(parseInt(value) - 1);
  };
  return (
    <div className="row pagination-block">
      <div className="col-sm-3 text-sm-left"></div>
      <div className="col-sm-6 mt-2 mt-sm-0 pagination">
        <Pagination size="sm" onClick={(e) => changePages(e.target.innerText)}>
          <Pagination.First
            key="First"
            disabled={cursorDirection === "FIRST" || !existPrev}
          />
          <Pagination.Prev
            key="Previous"
            disabled={cursorDirection === "FIRST" || !existPrev}
          />
          <Pagination.Next
            key="Next"
            disabled={cursorDirection === "LAST" || !existNext}
          />
          <Pagination.Last
            key="Last"
            disabled={cursorDirection === "LAST" || !existNext}
          />
        </Pagination>
      </div>
      <div className="col-sm-3 select-pages mt-2 mt-sm-0">
        <select
          value={perPage}
          className="form-control custom-select page-size float-sm-right"
          onChange={(e) => changePerPage(e.target.value)}
        >
          <option key={10}>10</option>
          <option key={20}>20</option>
          <option key={50}>50</option>
        </select>
      </div>
    </div>
  );
};
