import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import TimeInput from "react-time-picker-input";

import "./Datepicker.scss";

export const Timepicker = ({
  className,
  onChange,
  title,
  readOnly,
  name,
  value,
}) => {
  const handleChange = (date) => {
    name ? onChange(name, date) : onChange(date);
  };
  return (
    <div className={className}>
      <div className="form-group m-1">
        <div className="input-group-date timeinput-single">
          <label className="label-time-picker">
            {title}
            <TimeInput
              name={name}
              value={value}
              onChange={(e) => (readOnly ? "" : handleChange(e))}
            />
          </label>
        </div>
      </div>
    </div>
  );
};
