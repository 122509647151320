import * as types from "../actions/supportHistoryAction";

const initialState = {
  loading: false,
  totalResults: 0,
  page: 0,
  limit: "20",
  startDate: null,
  endDate: null,
  timeInterval: null,
  userUuid: null,
  restaurantUuid: "select",
  orderDirection: "DESC",
  sortBy: "requestTime",
};

const supportHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_SUPPORT_HISTORY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.CHANGE_SUPPORT_HISTORY_DATE_VALUE:
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        timeInterval: action.payload.timeInterval,
      };
    case types.GET_SUPPORT_HISTORY:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SUPPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.GET_SUPPORT_HISTORY_ERROR:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_SUPPORT_HISTORY_STORE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};

export default supportHistoryReducer;
