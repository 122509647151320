import * as types from "../actions/productionAction";

const initialState = {
  loading: false,
  totalResults: 0,
  started: true,
  pageNumber: 0,
  offset: 0,
  status: "INACTIVE",
  pageSize: "50",
  startTime: "00:00",
  testTime: "00:00",
};

const productionReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHANGE_PRODUCTION_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case types.GET_PRODUCTION:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PRODUCTION_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: action.message,
      };
    case types.GET_PRODUCTION_ERROR:
      return {
        ...initialState,
        loading: false,
      };
    case types.SUBMIT_ORDER:
      return {
        ...state,
        loading: false,
      };
    case types.RESET_PRODUCTION_STORE:
      return {
        ...state,
        ...initialState,
      };

    default:
      return state;
  }
};

export default productionReducer;
