import { getService } from "../../api/service";
import { submitOrder } from "../../api/packaging";
import { statusOrder } from "../../api/settings";

export const CHANGE_SERVICE_VALUE = "CHANGE_SERVICE_VALUE";
export const RESET_SERVICE_STORE = "RESET_SERVICE_STORE";

export const GET_SERVICE = "GET_SERVICE";
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_ERROR = "GET_SERVICE_ERROR";

export const SUBMIT_ORDER = "SUBMIT_ORDER";

export const getServiceAction = (params, setData) => {
  return async (dispatch) => {
    dispatch({ type: GET_SERVICE });
    try {
      const response = await getService(params);
      const { success, results } = response;
      if (success) {
        setData([...results]);
        dispatch(changeValue("totalResults", results.numberOfElements));
        dispatch({ type: GET_SERVICE_SUCCESS });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_SERVICE_ERROR });
    }
  };
};

export const submitOrderAction = (uuid, params) => {
  return async (dispatch) => {
    try {
      const response = await submitOrder(uuid, params);
      if (response) {
        dispatch({ type: SUBMIT_ORDER });
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const getStatus = (setData) => {
  return async (dispatch) => {
    try {
      const response = await statusOrder();
      const { success, results } = response;
      if (success) {
        setData(results.status);
        dispatch(
          changeValue(
            "testTime",
            results.testTime
              ? `${results.testTime[0]
                  .toString()
                  .padStart(2, "0")}:${results.testTime[1]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
        dispatch(
          changeValue(
            "startTime",
            results.startTime
              ? `${results.startTime[3]
                  .toString()
                  .padStart(2, "0")}:${results.startTime[4]
                  .toString()
                  .padStart(2, "0")}`
              : "00:00"
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const changeValue = (key, value) => ({
  type: CHANGE_SERVICE_VALUE,
  payload: {
    key,
    value,
  },
});

export const resetStore = () => ({
  type: RESET_SERVICE_STORE,
});
