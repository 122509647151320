import { combineReducers } from "redux";

import appReducer from "./appReducer";
import authLogReducer from "./authLogReducer";
import cateringReducer from "./cateringReducer";
import dlpsReducer from "./dlpsReducer";
import getSupportReducer from "./getSupportReducer";
import historyReducer from "./historyReducer";
import loginReducer from "./loginReducer";
import logsReducer from "./logsReducer";
import ordersAnalyticReducer from "./ordersAnalyticReducer";
import packagingReducer from "./packagingReducer";
import pinsReducer from "./pinsReducer";
import productionReducer from "./productionReducer";
import questionsReducer from "./questionReducer";
import recDoneReducer from "./recDoneReducer";
import serviceReducer from "./serviceReducer";
import sessionsReducer from "./sessionsReducer";
import settingsReducer from "./settingsReducer";
import supportHistoryReducer from "./supportHistoryReducer";
import transactionsAnalyticReducer from "./transactionsAnalyticReducer";
import transactionsReducer from "./transactionsReducer";

export const rootReducer = combineReducers({
  app: appReducer,
  login: loginReducer,
  services: serviceReducer,
  packaging: packagingReducer,
  setting: settingsReducer,
  histor: historyReducer,
  production: productionReducer,
  recDone: recDoneReducer,
  logs: logsReducer,
  questions: questionsReducer,
  supportHistory: supportHistoryReducer,
  getSupport: getSupportReducer,
  authLog: authLogReducer,
  transactions: transactionsReducer,
  sessions: sessionsReducer,
  transactionsAnalytic: transactionsAnalyticReducer,
  ordersAnalytic: ordersAnalyticReducer,
  dlps: dlpsReducer,
  pins: pinsReducer,
  catering: cateringReducer,
});

export default rootReducer;
