import Pagination from "react-bootstrap/Pagination";
import "./Pagination.scss";

export const Pages = ({
  totalRows,
  page,
  perPage,
  onChangePage,
  changePerPage,
}) => {
  const lastPage = Math.ceil(totalRows / perPage);
  const changePages = (value) => {
    (value.indexOf("«") !== -1 || value.indexOf("First") !== -1) &&
      onChangePage(0);
    (value.indexOf("‹") !== -1 || value.indexOf("Prev") !== -1) &&
      page !== 0 &&
      onChangePage(page - 1);
    (value.indexOf("›") !== -1 || value.indexOf("Next") !== -1) &&
      page !== lastPage - 1 &&
      onChangePage(page + 1);
    (value.indexOf("»") !== -1 || value.indexOf("Last") !== -1) &&
      onChangePage(lastPage - 1);
    !isNaN(parseInt(value)) && onChangePage(parseInt(value) - 1);
  };
  return (
    <div className="row pagination-block">
      <div className="col-sm-3 text-sm-left">
        <span className="d-inline-flex align-items-center h-100 text-muted border-0">
          {`${page * perPage + 1} - ${
            (page + 1) * perPage <= totalRows ? (page + 1) * perPage : totalRows
          } of ${totalRows}`}
        </span>
      </div>
      <div className="col-sm-6 mt-2 mt-sm-0 pagination">
        <Pagination size="sm" onClick={(e) => changePages(e.target.innerText)}>
          <Pagination.First key="First" disabled={page === 0} />
          <Pagination.Prev key="Previous" disabled={page === 0} />
          {lastPage < 4 ? (
            <>
              <Pagination.Item key={1} active={page === 0}>
                {1}
              </Pagination.Item>
              {lastPage > 1 && (
                <Pagination.Item key={2} active={page === 1}>
                  {2}
                </Pagination.Item>
              )}
              {lastPage > 2 && (
                <Pagination.Item key={2} active={page === 2}>
                  {3}
                </Pagination.Item>
              )}
            </>
          ) : page < 2 ? (
            <>
              <Pagination.Item key={1} active={page === 0}>
                {1}
              </Pagination.Item>
              <Pagination.Item key={2} active={page === 1}>
                {2}
              </Pagination.Item>
              <Pagination.Ellipsis disabled />
              <Pagination.Item key={lastPage - 1}>{lastPage}</Pagination.Item>
            </>
          ) : page > lastPage - 4 ? (
            <>
              <Pagination.Item key={1}>{1}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              <Pagination.Item
                key={lastPage - 2}
                active={page === lastPage - 3}
              >
                {lastPage - 2}
              </Pagination.Item>
              <Pagination.Item key={lastPage - 1} active={page == lastPage - 2}>
                {lastPage - 1}
              </Pagination.Item>
              <Pagination.Item key={lastPage} active={page === lastPage - 1}>
                {lastPage}
              </Pagination.Item>
            </>
          ) : (
            <>
              <Pagination.Item key={1}>{1}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              <Pagination.Item key={page + 1} active>
                {page + 1}
              </Pagination.Item>
              <Pagination.Item key={page + 2}>{page + 2}</Pagination.Item>
              <Pagination.Ellipsis disabled />
              <Pagination.Item key={lastPage - 1}>
                {totalRows === 0 ? 0 : lastPage}
              </Pagination.Item>
            </>
          )}
          <Pagination.Next key="Next" disabled={page === lastPage - 1} />
          <Pagination.Last key="Last" disabled={page === lastPage - 1} />
        </Pagination>
      </div>
      <div className="col-sm-3 select-pages mt-2 mt-sm-0">
        <select
          value={perPage}
          className="form-control custom-select page-size float-sm-right"
          onChange={(e) => changePerPage(e.target.value)}
        >
          <option key={10}>10</option>
          <option key={20}>20</option>
          <option key={50}>50</option>
        </select>
      </div>
    </div>
  );
};
